.content-inicio {
  height: 100vh;
  overflow: hidden;
}
.content-audio {
  position: fixed;
}
.cls-1 {
  fill: url(#Degradado_sin_nombre_31);
}
.cls-2 {
  fill: #f2f2f2;
}
.cls-3 {
  fill: #e6e6e6;
}
.cls-4 {
  fill: #f7f9f9;
}
.cls-104,
.cls-12,
.cls-167,
.cls-197,
.cls-198,
.cls-5 {
  fill: none;
}
.cls-5 {
  stroke: #491818;
}
.cls-104,
.cls-108,
.cls-123,
.cls-124,
.cls-135,
.cls-136,
.cls-140,
.cls-165,
.cls-166,
.cls-167,
.cls-168,
.cls-170,
.cls-171,
.cls-175,
.cls-176,
.cls-178,
.cls-179,
.cls-180,
.cls-181,
.cls-182,
.cls-183,
.cls-184,
.cls-186,
.cls-187,
.cls-188,
.cls-189,
.cls-190,
.cls-191,
.cls-192,
.cls-193,
.cls-194,
.cls-195,
.cls-196,
.cls-197,
.cls-198,
.cls-199,
.cls-201,
.cls-202,
.cls-203,
.cls-205,
.cls-206,
.cls-207,
.cls-5 {
  stroke-miterlimit: 10;
}
.cls-6 {
  fill: #004346;
}
.cls-140,
.cls-178,
.cls-186,
.cls-196,
.cls-7 {
  fill: #fff;
}
.cls-8 {
  fill: #172a39;
}
.cls-9 {
  fill: #0abc8a;
}
.cls-10 {
  fill: #508992;
}
.cls-11 {
  fill: #00aa4c;
}
.cls-13 {
  fill: #979694;
}
.cls-14 {
  fill: #015948;
}
.cls-15 {
  fill: #579d5c;
}
.cls-16 {
  fill: #00674e;
}
.cls-17 {
  fill: #00894e;
}
.cls-18 {
  fill: #01694f;
}
.cls-19 {
  fill: #027445;
}
.cls-20 {
  fill: #014639;
}
.cls-21 {
  fill: #01522d;
}
.cls-22 {
  fill: #7ca05c;
}
.cls-23 {
  fill: #289159;
}
.cls-24 {
  fill: #227759;
}
.cls-25 {
  fill: #4b6338;
}
.cls-26 {
  fill: #003c30;
}
.cls-27 {
  fill: #003328;
}
.cls-28 {
  fill: #003930;
}
.cls-29 {
  fill: #015d4a;
}
.cls-30 {
  fill: #00554a;
}
.cls-31 {
  fill: #00564c;
}
.cls-32 {
  fill: #00544a;
}
.cls-33 {
  fill: #00544c;
}
.cls-34 {
  fill: #003b35;
}
.cls-35 {
  fill: #007146;
}
.cls-36 {
  fill: #006538;
}
.cls-37 {
  fill: #014e2a;
}
.cls-38 {
  fill: #008b4f;
}
.cls-39 {
  fill: #00644b;
}
.cls-40 {
  fill: #005037;
}
.cls-41 {
  fill: #01664b;
}
.cls-42 {
  fill: #005f34;
}
.cls-43 {
  fill: #00724f;
}
.cls-44 {
  fill: #01734f;
}
.cls-45 {
  fill: #005e34;
}
.cls-46 {
  fill: #164e3e;
}
.cls-47 {
  fill: #569c5b;
}
.cls-48 {
  fill: #5d9e5e;
}
.cls-49 {
  fill: #599e5d;
}
.cls-50 {
  fill: #3a683c;
}
.cls-51 {
  fill: #569d5d;
}
.cls-52 {
  fill: #004438;
}
.cls-53 {
  fill: #00463a;
}
.cls-54 {
  fill: #014739;
}
.cls-55 {
  fill: #01493a;
}
.cls-56 {
  fill: #019153;
}
.cls-57 {
  fill: #399658;
}
.cls-58 {
  fill: #479958;
}
.cls-59 {
  fill: #379658;
}
.cls-60 {
  fill: #289158;
}
.cls-61 {
  fill: #3b815a;
}
.cls-62 {
  fill: #3a815a;
}
.cls-63 {
  fill: #367f59;
}
.cls-64 {
  fill: #3d835b;
}
.cls-65 {
  fill: #2b5b34;
}
.cls-66 {
  fill: #088d55;
}
.cls-67 {
  fill: #32825b;
}
.cls-68 {
  fill: #3a955f;
}
.cls-69 {
  fill: #37935a;
}
.cls-70 {
  fill: #3b965f;
}
.cls-71 {
  fill: #0c7055;
}
.cls-72 {
  fill: #1e9058;
}
.cls-73 {
  fill: #38905e;
}
.cls-74 {
  fill: #1b8e58;
}
.cls-75 {
  fill: #0f8c57;
}
.cls-76 {
  fill: #0b6951;
}
.cls-77 {
  fill: #35935b;
}
.cls-78 {
  fill: #078c53;
}
.cls-79 {
  fill: #299258;
}
.cls-80 {
  fill: #0a6f55;
}
.cls-81 {
  fill: #087257;
}
.cls-82 {
  fill: #054436;
}
.cls-83 {
  fill: #5da987;
}
.cls-84 {
  fill: #007445;
}
.cls-85 {
  fill: #087759;
}
.cls-86 {
  fill: #007148;
}
.cls-87 {
  fill: #029053;
}
.cls-88 {
  fill: #008a4f;
}
.cls-89 {
  fill: #3f5e35;
}
.cls-90 {
  fill: #008c4f;
}
.cls-91 {
  fill: #008a4e;
}
.cls-92 {
  fill: #077649;
}
.cls-93 {
  fill: #014842;
}
.cls-94 {
  fill: #007749;
}
.cls-95 {
  fill: #007345;
}
.cls-96 {
  fill: #004738;
}
.cls-97 {
  fill: #597742;
}
.cls-98 {
  fill: #779f5c;
}
.cls-99 {
  fill: #007849;
}
.cls-100 {
  fill: #759f5c;
}
.cls-101 {
  fill: #559a59;
}
.cls-102 {
  fill: #769f5c;
}
.cls-103 {
  opacity: 0.45;
  fill: url(#Degradado_sin_nombre_31-2);
}
.cls-104 {
  stroke: #ff0;
}
.cls-105,
.cls-135 {
  fill: #606060;
}
.cls-106 {
  fill: #8d9095;
}
.cls-107 {
  fill: #8ca055;
}
.cls-108 {
  fill: #f4d851;
}
.cls-108,
.cls-123 {
  stroke: #c1a944;
}
.cls-109 {
  fill: #4d586a;
}
.cls-110 {
  fill: #fcfcfd;
}
.cls-111 {
  fill: #323844;
}
.cls-112 {
  fill: #638148;
}
.cls-113 {
  fill: #8a8580;
}
.cls-114 {
  fill: #7e92b3;
}
.cls-115 {
  fill: #a2aab7;
}
.cls-116 {
  fill: #95b0c8;
}
.cls-117 {
  fill: #c1cedb;
}
.cls-118 {
  fill: #433d3f;
}
.cls-119 {
  fill: #a3a3a2;
}
.cls-120 {
  fill: #999;
}
.cls-121 {
  fill: #c5e7fd;
}
.cls-122 {
  fill: #a1dafb;
}
.cls-123 {
  fill: #31550e;
}
.cls-124 {
  fill: #535a5f;
  stroke: #8d9095;
  stroke-width: 2px;
}
.cls-125 {
  fill: #c0d0db;
}
.cls-126 {
  fill: #9ca7ad;
}
.cls-127 {
  fill: #636363;
}
.cls-128 {
  fill: #a6a7a5;
}
.cls-129 {
  fill: #e30613;
}
.cls-130 {
  fill: #82030f;
}
.cls-131 {
  fill: #efabb5;
}
.cls-132 {
  fill: #c1536e;
}
.cls-133 {
  fill: #dd8092;
}
.cls-134 {
  fill: #8f8f8c;
}
.cls-135 {
  stroke: #f4d851;
}
.cls-136,
.cls-163,
.cls-165,
.cls-170 {
  fill: #6a6a6a;
}
.cls-136,
.cls-165,
.cls-166,
.cls-171,
.cls-175,
.cls-176,
.cls-178,
.cls-179,
.cls-180,
.cls-181,
.cls-182,
.cls-183,
.cls-184,
.cls-187,
.cls-188,
.cls-189,
.cls-190,
.cls-191,
.cls-192,
.cls-193,
.cls-194,
.cls-195,
.cls-196,
.cls-197,
.cls-199,
.cls-201,
.cls-202,
.cls-203,
.cls-205,
.cls-206,
.cls-207 {
  stroke: #000;
}
.cls-137 {
  fill: #434343;
}
.cls-138 {
  fill: #88939b;
}
.cls-139 {
  fill: #cdd6df;
}
.cls-140 {
  stroke: #f7931e;
}
.cls-141 {
  fill: #d5d5d6;
}
.cls-142 {
  fill: url(#Degradado_sin_nombre_21);
}
.cls-143 {
  fill: url(#Degradado_sin_nombre_21-2);
}
.cls-144 {
  fill: url(#Degradado_sin_nombre_21-3);
}
.cls-145 {
  fill: url(#Degradado_sin_nombre_21-4);
}
.cls-146 {
  fill: #858da8;
}
.cls-147 {
  fill: #9ba9ce;
}
.cls-148 {
  fill: #79acc6;
}
.cls-149 {
  fill: #8a8a8a;
}
.cls-150 {
  fill: url(#Degradado_sin_nombre_21-5);
}
.cls-151 {
  fill: url(#Degradado_sin_nombre_21-6);
}
.cls-152 {
  fill: url(#Degradado_sin_nombre_21-7);
}
.cls-153 {
  fill: url(#Degradado_sin_nombre_21-8);
}
.cls-154 {
  fill: url(#Degradado_sin_nombre_21-9);
}
.cls-155 {
  fill: url(#Degradado_sin_nombre_21-10);
}
.cls-156 {
  fill: url(#Degradado_sin_nombre_21-11);
}
.cls-157 {
  fill: url(#Degradado_sin_nombre_21-12);
}
.cls-158 {
  fill: url(#Degradado_sin_nombre_21-13);
}
.cls-159 {
  fill: url(#Degradado_sin_nombre_21-14);
}
.cls-160 {
  fill: url(#Degradado_sin_nombre_21-15);
}
.cls-161 {
  fill: url(#Degradado_sin_nombre_21-16);
}
.cls-162 {
  fill: url(#Degradado_sin_nombre_21-17);
}
.cls-164,
.cls-166 {
  fill: #d30000;
}
.cls-165,
.cls-194,
.cls-195,
.cls-196,
.cls-201,
.cls-202,
.cls-203 {
  stroke-width: 0.25px;
}
.cls-167 {
  stroke: #a1acb3;
}
.cls-168 {
  fill: #d6e9f4;
  stroke: #88939b;
}
.cls-169 {
  fill: #9ea0a2;
}
.cls-170 {
  stroke: #fff;
}
.cls-170,
.cls-187 {
  stroke-width: 0.5px;
}
.cls-171 {
  fill: url(#Degradado_sin_nombre_11);
}
.cls-172 {
  fill: #a8b0b7;
}
.cls-173 {
  fill: #604c39;
}
.cls-174,
.cls-182 {
  fill: #ffffb5;
}
.cls-175 {
  fill: #787878;
}
.cls-176 {
  fill: #bfbfbf;
}
.cls-177 {
  fill: #a5aba0;
}
.cls-179 {
  fill: #ddd8c2;
}
.cls-180 {
  fill: #fffccb;
}
.cls-181 {
  fill: #887b63;
}
.cls-183 {
  fill: #f2c191;
}
.cls-184 {
  fill: #cec8bb;
}
.cls-185 {
  fill: #cbb77a;
}
.cls-186,
.cls-198 {
  stroke: #d9e021;
}
.cls-187 {
  fill: #b60000;
}
.cls-188 {
  fill: #dedede;
}
.cls-189 {
  fill: red;
}
.cls-190 {
  fill: #d1d1cf;
}
.cls-190,
.cls-191,
.cls-193,
.cls-197 {
  stroke-width: 0.75px;
}
.cls-191,
.cls-192 {
  fill: silver;
}
.cls-193 {
  fill: #730000;
}
.cls-194 {
  fill: #404040;
}
.cls-195 {
  fill: #ccd8e6;
}
.cls-199 {
  fill: #81744d;
}
.cls-200 {
  fill: #c3b18f;
}
.cls-201 {
  fill: #c6c6cb;
}
.cls-202 {
  fill: #636365;
}
.cls-203 {
  fill: #b8b99d;
}
.cls-204 {
  fill: #94866c;
}
.cls-205 {
  fill: #ffffd1;
}
.cls-206 {
  fill: #ffffd7;
}
.cls-207 {
  fill: #e1cca4;
}
.cls-208 {
  fill: #ecdf87;
}
.cls-209 {
  fill: #eadf8c;
}
.cls-210 {
  fill: #f4f2cd;
}
.cls-211 {
  fill: #f6efc2;
}
.cls-212 {
  fill: #5aa344;
}
.cls-213 {
  fill: #f1eec4;
}
.cls-214 {
  fill: #edecaf;
}
.cls-215 {
  fill: #93b354;
}
.cls-216 {
  fill: #f4edb9;
}
.cls-217 {
  fill: #acc45d;
}
.cls-218 {
  fill: #f9eebb;
}
.cls-219 {
  fill: #c6d290;
}
.cls-220 {
  fill: #b6cc52;
}
.cls-221 {
  fill: #eef0cd;
}
.cls-222 {
  fill: #f9f0c1;
}
.cls-223 {
  fill: #54aa7c;
}
.cls-224 {
  fill: #97bc7e;
}
.cls-225 {
  fill: #f9ecb0;
}
.cls-226 {
  fill: #eee850;
}
.cls-227 {
  fill: #a1b972;
}
.cls-228 {
  fill: #7e985e;
}
.cls-229 {
  fill: #b2d181;
}
.cls-230 {
  fill: #61832d;
}
.cls-231 {
  fill: #c3e1ad;
}
.cls-232 {
  fill: #dfebc0;
}
.cls-233 {
  fill: #eef3ac;
}
.cls-234 {
  fill: #9dbe64;
}
.cls-235 {
  fill: #c0c877;
}
.cls-236 {
  fill: #d2e3d0;
}
.cls-237 {
  fill: #8f9659;
}
.cls-238 {
  fill: #58955e;
}
.cls-239 {
  fill: #94a297;
}
.cls-240 {
  fill: #c7ce48;
}
.cls-241 {
  fill: #cace48;
}
.cls-242 {
  fill: #cbce49;
}
.cls-243 {
  fill: #deda34;
}
.cls-244 {
  fill: #aac74b;
}
.cls-245 {
  fill: #91a63d;
}
.cls-246 {
  fill: #c6cd6a;
}
.cls-247 {
  fill: #f9ecaf;
}
.cls-248 {
  fill: #fbeeb5;
}
.cls-249 {
  fill: #f4e931;
}
.cls-250 {
  fill: #faecb5;
}
.cls-251 {
  fill: #f4efb3;
}
.cls-252 {
  fill: #8c9f3d;
}
.cls-253 {
  fill: #916b93;
}
.cls-254 {
  fill: #e8e24a;
}
.cls-255 {
  fill: #58a639;
}
.cls-256 {
  fill: #879724;
}
.cls-257 {
  fill: #b3c35d;
}
.cls-258 {
  fill: #91a840;
}
.cls-259 {
  fill: #e3da66;
}
.cls-260 {
  fill: #879f32;
}
.cls-261 {
  fill: #cad97e;
}
.cls-262 {
  fill: #f9edb3;
}
.cls-263 {
  fill: #a6bd4e;
}
.cls-264 {
  fill: #a1b556;
}
.cls-265 {
  fill: #fcedb6;
}
.cls-266 {
  fill: #f2f1af;
}
.cls-267 {
  fill: #e0e995;
}
.cls-268 {
  fill: #e8f1b1;
}
.cls-269 {
  fill: #919833;
}
.cls-270 {
  fill: #739942;
}
.cls-271 {
  fill: #9fb65e;
}
.cls-272 {
  fill: #bac77c;
}
.cls-273 {
  fill: #99b64b;
}
.cls-274 {
  fill: #628930;
}
.cls-275 {
  fill: #4c6d16;
}
.cls-276 {
  fill: #9bb840;
}
.cls-277 {
  fill: #96ba46;
}
.cls-278 {
  fill: #aacf5e;
}
.cls-279 {
  fill: #789d3f;
}
.cls-280 {
  fill: #618838;
}
.cls-281 {
  fill: #8faf56;
}
.cls-282 {
  fill: #74983d;
}
.cls-283 {
  fill: #a4bc64;
}
.cls-284 {
  fill: #d6dca2;
}
.cls-285 {
  fill: #83a341;
}
.cls-286 {
  fill: #94ae47;
}
.cls-287 {
  fill: #5b7e30;
}
.cls-288 {
  fill: #55822a;
}
.cls-289 {
  fill: #76a245;
}
.cls-290 {
  fill: #4d9334;
}
.cls-291 {
  fill: #497c2c;
}
.cls-292 {
  fill: #e9f1b1;
}
.cls-293 {
  fill: #437524;
}
.cls-294 {
  fill: #5c7e32;
}
.cls-295 {
  fill: #94af50;
}
.cls-296 {
  fill: #698b33;
}
.cls-297 {
  fill: #f7efba;
}
.cls-298 {
  fill: #dbe5a0;
}
.cls-299 {
  fill: #84a634;
}
.cls-300 {
  fill: #ede937;
}
.cls-301 {
  fill: #48ac54;
}
.cls-302 {
  fill: #738e56;
}
.cls-303 {
  fill: #edec33;
}
.cls-304 {
  fill: #e0f2d1;
}
.cls-305 {
  fill: #ccd369;
}
.cls-306 {
  fill: #ebeeb8;
}
.cls-307 {
  fill: #b7c952;
}
.cls-308 {
  fill: #94bc44;
}
.cls-309 {
  fill: #94bc42;
}
.cls-310 {
  fill: #9ebe44;
}
.cls-311 {
  fill: #9fb74d;
}
.cls-312 {
  fill: #e3e63e;
}
.cls-313 {
  fill: #99b144;
}
.cls-314 {
  fill: #5e882c;
}
.cls-315 {
  fill: #4fa73c;
}
.cls-316 {
  fill: #a8ba4b;
}
.cls-317 {
  fill: #e6e93c;
}
.cls-318 {
  fill: #ebe432;
}
.cls-319 {
  fill: #4fb55c;
}
.cls-320 {
  fill: #f3edb0;
}
.cls-321 {
  fill: #5e872b;
}
.cls-322 {
  fill: #7a9b2d;
}
.cls-323 {
  fill: #5ba033;
}
.cls-324 {
  fill: #41751d;
}
.cls-325 {
  fill: #efec33;
}
.cls-326 {
  fill: #efed2d;
}
.cls-327 {
  fill: #658326;
}
.cls-328 {
  fill: #f6f3a9;
}
.cls-329 {
  fill: #efeb2f;
}
.cls-330 {
  fill: #7ba026;
}
.cls-331 {
  fill: #608e2c;
}
.cls-332 {
  fill: #eaeda5;
}
.cls-333 {
  fill: #6f9021;
}
.cls-334 {
  fill: #56a636;
}
.cls-335 {
  fill: #58b04f;
}
.cls-336 {
  fill: #879d21;
}
.cls-337 {
  fill: #e1e990;
}
.cls-338 {
  fill: #b6a659;
}
.cls-339 {
  fill: #a9ba45;
}
.cls-340 {
  fill: #b9a359;
}
.cls-341 {
  fill: #8da939;
}
.cls-342 {
  fill: #ebda64;
}
.cls-343 {
  fill: #61a538;
}
.cls-344 {
  fill: #f1efb8;
}
.cls-345 {
  fill: #dfdb63;
}
.cls-346 {
  fill: #ebe738;
}
.cls-347 {
  fill: #82a037;
}
.cls-348 {
  fill: #92758e;
}
.cls-349 {
  fill: #6d9131;
}
.cls-350 {
  fill: #efea3d;
}
.cls-351 {
  fill: #efe82e;
}
.cls-352 {
  fill: #4f751b;
}
.cls-353 {
  fill: #6aad34;
}
.cls-354 {
  fill: #6bac39;
}
.cls-355 {
  fill: #7db33c;
}
.cls-356 {
  fill: #6cad38;
}
.cls-357 {
  fill: #457115;
}
.cls-358 {
  fill: #ece3a2;
}
.cls-359 {
  fill: #69ab34;
}
.cls-360 {
  fill: #797c46;
}
.cls-361 {
  fill: #96ad3c;
}
.cls-362 {
  fill: #f1da5e;
}
.cls-363 {
  fill: #f1e930;
}
.cls-364 {
  fill: #869a2e;
}
.cls-365 {
  fill: #c9ce48;
}
.cls-366 {
  fill: #f1f0b4;
}
.cls-367 {
  fill: #f0f0b1;
}
.cls-368 {
  fill: #e7eda9;
}
.cls-369 {
  fill: #74963c;
}
.cls-370 {
  fill: #efefb0;
}
.cls-371 {
  fill: #5b8131;
}
.cls-372 {
  fill: #e9dd6b;
}
.cls-373 {
  fill: #e3eaa7;
}
.cls-374 {
  fill: #507218;
}
.cls-375 {
  fill: #708f26;
}
.cls-376 {
  fill: #dfde74;
}
.cls-377 {
  fill: #467221;
}
.cls-378 {
  fill: #e9eeaf;
}
.cls-379 {
  fill: #eaeeb4;
}
.cls-380 {
  fill: #f2f0be;
}
.cls-381 {
  fill: #dde4a7;
}
.cls-382 {
  fill: #e7eeb2;
}
.cls-383 {
  fill: #538231;
}
.cls-384 {
  fill: #608836;
}
.cls-385 {
  fill: #eff2b6;
}
.cls-386 {
  fill: #e4efb1;
}
.cls-387 {
  fill: #f0eec0;
}
.cls-388 {
  fill: #e9efaf;
}
.cls-389 {
  fill: #d5e69c;
}
.cls-390 {
  fill: #447326;
}
.cls-391 {
  fill: #cde7a3;
}
.cls-392 {
  fill: #e5f6b5;
}
.cls-393 {
  fill: #e1efb2;
}
.cls-394 {
  fill: #d5eba6;
}
.cls-395 {
  fill: #e1eca5;
}
.cls-396 {
  fill: #c7df90;
}
.cls-397 {
  fill: #517927;
}
.cls-398 {
  fill: #dbe59d;
}
.cls-399 {
  fill: #599c3f;
}
.cls-400 {
  fill: #597d1d;
}
.cls-401 {
  fill: #e2ed45;
}
.cls-402 {
  fill: #f1eb2d;
}
.cls-403 {
  fill: #ecedb6;
}
.cls-404 {
  fill: #e7e9ac;
}
.cls-405 {
  fill: #f3f2b9;
}
.cls-406 {
  fill: #587737;
}
.cls-407 {
  fill: #c9dd91;
}
.cls-408 {
  fill: #e7edb2;
}
.cls-409 {
  fill: #deedaf;
}
.cls-410 {
  fill: #e8f1b3;
}
.cls-411 {
  fill: #eaeea7;
}
.cls-412 {
  fill: #dceca1;
}
.cls-413 {
  fill: #e5e7a0;
}
.cls-414 {
  fill: #dce94c;
}
.cls-415 {
  fill: #e7f0a8;
}
.cls-416 {
  fill: #eaf1ab;
}
.cls-417 {
  fill: #4a8d37;
}
.cls-418 {
  fill: #d7e2a3;
}
.cls-419 {
  fill: #88a633;
}
.cls-420 {
  fill: #d8e342;
}
.cls-421 {
  fill: #c5d643;
}
.cls-422 {
  fill: #daea44;
}
.cls-423 {
  fill: #4c7817;
}
.cls-424 {
  fill: #76884d;
}
.cls-425 {
  fill: #5e8330;
}
.cls-426 {
  fill: #587e12;
}
.cls-427 {
  fill: #99b647;
}
.cls-428 {
  fill: #6b8e27;
}
.cls-429 {
  fill: #e4e938;
}
.cls-430 {
  fill: #6a9232;
}
.cls-431 {
  fill: #4e781d;
}
.cls-432 {
  fill: #e5f1a9;
}
.cls-433 {
  fill: #ecefb1;
}
.cls-434 {
  fill: #9dac52;
}
.cls-435 {
  fill: #577c15;
}
.cls-436 {
  fill: #779e40;
}
.cls-437 {
  fill: #256531;
}
.cls-438 {
  fill: #6b909c;
}
.cls-439 {
  fill: #95704b;
}
.cls-440 {
  fill: #614a35;
}
.cls-441 {
  fill: #246531;
}
.cls-442 {
  fill: #779f40;
}
.cls-443 {
  fill: #8a5f3b;
}
.cls-444 {
  fill: #8bc440;
}
.cls-445 {
  fill: #a8754d;
}
.cls-446 {
  fill: #6c8f99;
}
.cls-447 {
  fill: #03572d;
}
.cls-448 {
  fill: #62452c;
}
.cls-449 {
  fill: #04572d;
}
.cls-450 {
  fill: #98774e;
}
.cls-451 {
  fill: #a9cd39;
}
.cls-452 {
  fill: #02582d;
}
.cls-453 {
  fill: #a9cd3b;
}
.cls-454 {
  fill: #6b919c;
}
.cls-455 {
  fill: #8cc444;
}
.cls-456 {
  fill: #7d5f45;
}
.cls-457 {
  fill: #766043;
}
.cls-458 {
  fill: #9ab377;
}
.cls-459 {
  fill: #977e58;
}
.cls-460 {
  fill: #246632;
}
.cls-461 {
  fill: #03582d;
}
.cls-462 {
  fill: #77a040;
}
.cls-463 {
  fill: #6a909b;
}
.cls-464 {
  fill: #779f41;
}
.cls-465 {
  fill: #7c654b;
}
.cls-466 {
  fill: #867c64;
}
.cls-467 {
  fill: #3a5630;
}
.cls-468 {
  fill: #02572d;
}
.cls-469 {
  fill: #aacd39;
}
.cls-470 {
  fill: #769f40;
}
.cls-471 {
  fill: #256532;
}
.cls-472 {
  fill: #266530;
}
.cls-473 {
  fill: #aacd3c;
}
.cls-474 {
  fill: #769e41;
}
.cls-475 {
  fill: #05582c;
}
.cls-476 {
  fill: #a47650;
}
.cls-477 {
  fill: #60472e;
}
.cls-478 {
  fill: #6b909a;
}
.cls-479 {
  fill: #a9cd3f;
}
.cls-480 {
  fill: #789f42;
}
.cls-481 {
  fill: #806246;
}
.cls-482 {
  fill: #4f5333;
}
.cls-483 {
  fill: #2f7445;
}
.cls-484 {
  fill: #c1d578;
}
.cls-485 {
  fill: #6f9b46;
}
.cls-486 {
  fill: #887044;
}
.cls-487 {
  fill: #48532e;
}
.cls-488 {
  fill: #2c6216;
}
.cls-489 {
  fill: #8b623f;
}
.cls-490 {
  fill: #709f45;
}
.cls-491 {
  fill: #b9d689;
}
.cls-492 {
  fill: #594c2c;
}
.cls-493 {
  fill: #2b622c;
}
.cls-494 {
  fill: #256431;
}
.cls-495 {
  fill: #6a909a;
}
.cls-496 {
  fill: #04582c;
}
.cls-497 {
  fill: #769f42;
}
.cls-498 {
  fill: #749e44;
}
.cls-499 {
  fill: #276332;
}
.cls-500 {
  fill: #7b6d52;
}
.cls-501 {
  fill: #0b582c;
}
.cls-502 {
  fill: #326232;
}
.cls-503 {
  fill: #7a6d50;
}
.cls-504 {
  fill: #759f40;
}
.cls-505 {
  fill: #759f3f;
}
.cls-506 {
  fill: #28652d;
}
.cls-507 {
  fill: #72694c;
}
.cls-508 {
  fill: #7e654a;
}
.cls-509 {
  fill: #3d5831;
}
.cls-510 {
  fill: #31693e;
}
.cls-511 {
  fill: #03572c;
}
.cls-512 {
  fill: #266531;
}
.cls-513 {
  fill: #256632;
}
.cls-514 {
  fill: #27642f;
}
.cls-515 {
  fill: #aacc3d;
}
.cls-516 {
  fill: #abcd3c;
}
.cls-517 {
  fill: #6a9199;
}
.cls-518 {
  fill: #61462c;
}
.cls-519 {
  fill: #a37553;
}
.cls-520 {
  fill: #769d45;
}
.cls-521 {
  fill: #2a6530;
}
.cls-522 {
  fill: #786146;
}
.cls-523 {
  fill: #719d47;
}
.cls-524 {
  fill: #56512b;
}
.cls-525 {
  fill: #95794a;
}
.cls-526 {
  fill: #8a603f;
}
.cls-527 {
  fill: #2a632e;
}
.cls-528 {
  fill: #759e48;
}
.cls-529 {
  fill: #f9c551;
}
.cls-530 {
  fill: #e57844;
}
.cls-531 {
  fill: #ed915d;
}
.cls-532 {
  fill: #fcc6a9;
}
.cls-533 {
  fill: #3c5765;
}
.cls-534 {
  fill: #3c5664;
}
.cls-535 {
  fill: #94d8e7;
}
.cls-536 {
  fill: #f3b036;
}
.cls-537 {
  fill: #f9c650;
}
.cls-538 {
  fill: #f4b036;
}
.cls-539 {
  fill: #5e7a83;
}
.cls-540 {
  fill: #304648;
}
.cls-541 {
  fill: #273e48;
}
.cls-542 {
  fill: #fbc7a9;
}
.cls-543 {
  fill: #e68854;
}
.cls-544 {
  fill: #3c5764;
}
.cls-545 {
  fill: #74cae0;
}
.cls-546 {
  fill: #304754;
}
.cls-547 {
  fill: #4b5c5e;
}
.cls-548 {
  fill: #e47a46;
}
.cls-549 {
  fill: #a9e3ef;
}
.cls-550 {
  fill: #2f5261;
}
.cls-551 {
  fill: #74c9e1;
}
.cls-552 {
  fill: #e7c4a9;
}
.cls-553 {
  fill: #4db5d1;
}
.cls-554 {
  fill: #50b4ce;
}
.cls-555 {
  fill: #515d5d;
}
.cls-556 {
  fill: #565856;
}
.cls-557 {
  fill: #c9f0f8;
}
.cls-558 {
  fill: #a63e3a;
}
.cls-559 {
  fill: #bb3e40;
}
.cls-560 {
  fill: #173561;
}
.cls-561 {
  fill: #912f30;
}
.cls-562 {
  fill: #fbb17e;
}
.cls-563 {
  fill: #a53e39;
}
.cls-564 {
  fill: #c35647;
}
.cls-565 {
  fill: #fcb181;
}
.cls-566 {
  fill: #fbb07e;
}
.cls-567 {
  fill: #fbb280;
}
.cls-568 {
  fill: #bf574b;
}
.cls-569 {
  fill: #202c52;
}
.cls-570 {
  fill: #6f6376;
}
.cls-571 {
  fill: #923030;
}
.cls-572 {
  fill: #2b468a;
}
.cls-573 {
  fill: #faaf7e;
}
.cls-574 {
  fill: #314687;
}
.cls-575 {
  fill: #ee9949;
}
.cls-576 {
  fill: #2b4689;
}
.cls-577 {
  fill: #243864;
}
.cls-578 {
  fill: #243863;
}
.cls-579 {
  fill: #f0a250;
}
.cls-580 {
  fill: #da935e;
}
.cls-581 {
  fill: #243862;
}
.cls-582 {
  fill: #d8925e;
}
.cls-583 {
  fill: #e6873a;
}
.cls-584 {
  fill: #e68c39;
}
.cls-585 {
  fill: #ca7e47;
}
.cls-586 {
  fill: #db945f;
}
.cls-587 {
  fill: #db9560;
}
.cls-588 {
  fill: #d2722a;
}
.cls-589 {
  fill: #e4873b;
}
.cls-590 {
  fill: #b34e46;
}
.cls-591 {
  fill: #d66814;
}
.cls-592 {
  fill: #e28736;
}
.cls-593 {
  fill: #e48837;
}
.cls-594 {
  fill: #2e3a64;
}
.cls-595 {
  fill: #74abbe;
}
.cls-596 {
  fill: #b26e42;
}
.cls-597 {
  fill: #ae724b;
}
.cls-598 {
  fill: #598d9a;
}
.cls-599 {
  fill: #283960;
}
.cls-600 {
  fill: #b57144;
}
.cls-601 {
  fill: #293860;
}
.cls-602 {
  fill: #e5873b;
}
.cls-603 {
  fill: #324f88;
}
.cls-604 {
  fill: #e7cab1;
}
.cls-605 {
  fill: #23447a;
}
.cls-606 {
  fill: #2c4d86;
}
.cls-607 {
  fill: #5d8e9b;
}
.cls-608 {
  fill: #a53f3a;
}
.cls-609 {
  fill: #2d468a;
}
.cls-610 {
  fill: #243964;
}
.cls-611 {
  fill: #feba40;
}
.cls-612 {
  fill: #fcb27f;
}
.cls-613 {
  fill: #253a65;
}
.cls-614 {
  fill: #fcb380;
}
.cls-615 {
  fill: #fca616;
}
.cls-616 {
  fill: #da8842;
}
.cls-617 {
  fill: #fbb17f;
}
.cls-618 {
  fill: #1c2560;
}
.cls-619 {
  fill: #8a3b08;
}
.cls-620 {
  fill: #fdb93f;
}
.cls-621 {
  fill: #fcb381;
}
.cls-622 {
  fill: #fca71d;
}
.cls-623 {
  fill: #fdb17d;
}
.cls-624 {
  fill: #b94d2e;
}
.cls-625 {
  fill: #f7a971;
}
.cls-626 {
  fill: #913035;
}
.cls-627 {
  fill: #26395f;
}
.cls-628 {
  fill: #374786;
}
.cls-629 {
  fill: #fda513;
}
.cls-630 {
  fill: #24305c;
}
.cls-631 {
  fill: #f5c8a4;
}
.cls-632 {
  fill: #7ea4b6;
}
.cls-633 {
  fill: #ed9549;
}
.cls-634 {
  fill: #bbd1ed;
}
.cls-635 {
  fill: #5688d4;
}
.cls-636 {
  fill: #343855;
}
.cls-637 {
  fill: #f38d2a;
}
.cls-638 {
  fill: #333855;
}
.cls-639 {
  fill: #eca988;
}
.cls-640 {
  fill: #ea4a48;
}
.cls-641 {
  fill: #232337;
}
.cls-642 {
  fill: #232438;
}
.cls-643 {
  fill: #eba988;
}
.cls-644 {
  fill: #4977b5;
}
.cls-645 {
  fill: #2e273e;
}
.cls-646 {
  fill: #ca7533;
}
.cls-647 {
  fill: #03061a;
}
.cls-648 {
  fill: #050829;
}
.cls-649 {
  fill: #252742;
}
.cls-650 {
  fill: #5e9bea;
}
.cls-651 {
  fill: #eaa887;
}
.cls-652 {
  fill: #4271ae;
}
.cls-653 {
  fill: #4874af;
}
.cls-654 {
  fill: #cd8f6a;
}
.cls-655 {
  fill: #ab7c65;
}
.cls-656 {
  fill: #d07b25;
}
.cls-657 {
  fill: #778cb9;
}
.cls-658 {
  fill: #324666;
}
.cls-659 {
  fill: #e9734e;
}
.cls-660 {
  fill: #343856;
}
.cls-661 {
  fill: #eca987;
}
.cls-662 {
  fill: #eba987;
}
.cls-663 {
  fill: #c3c3c3;
}
.cls-664 {
  fill: #eed9d5;
}
.cls-665 {
  fill: #d2bfbc;
}
.cls-666 {
  fill: #2e354a;
}
.cls-667 {
  fill: #060827;
}
.cls-668 {
  fill: #050d29;
}
.cls-669 {
  fill: #26344c;
}
.cls-670 {
  fill: #eed9d3;
}
.cls-671 {
  fill: #f0eeee;
}
.cls-672 {
  fill: #edd9d4;
}
.cls-673 {
  fill: #eda988;
}
.cls-674 {
  fill: #eddad5;
}
.cls-675 {
  fill: #e9734d;
}
.cls-676 {
  fill: #cb6544;
}
.cls-677 {
  fill: #17343e;
}
.cls-678 {
  fill: #222438;
}
.cls-679 {
  fill: #e8744e;
}
.cls-680 {
  fill: #eda885;
}
.cls-681 {
  fill: #272b44;
}
.cls-682 {
  fill: #171a35;
}
.cls-683 {
  fill: #050826;
}
.cls-684 {
  fill: #00040d;
}
.cls-685 {
  fill: #0b0b2b;
}
.cls-686 {
  fill: #eda987;
}
.cls-687 {
  fill: #c7b3ae;
}
.cls-688 {
  fill: #3a344a;
}
.cls-689 {
  fill: #b07c5e;
}
.cls-690 {
  fill: #bcd0ce;
}
.cls-691 {
  fill: #744c29;
}
.cls-692 {
  fill: #499db1;
}
.cls-693 {
  fill: #153952;
}
.cls-694 {
  fill: #92a94c;
}
.cls-695 {
  fill: #653a17;
}
.cls-696 {
  fill: #d2e3e2;
}
.cls-697 {
  fill: #5baac3;
}
.cls-698 {
  fill: #a0ba4f;
}
.cls-699 {
  fill: #343434;
}
.cls-700 {
  fill: #4295a4;
}
.cls-701 {
  fill: #be1e2d;
}
.cls-702 {
  fill: #151314;
}
.cls-703 {
  fill: #efbb97;
}
.cls-704 {
  fill: #231f20;
}
.cls-705 {
  fill: #323031;
}
.cls-706 {
  fill: #ebb897;
}
.cls-707 {
  fill: #ba1e2d;
}
.cls-708 {
  fill: #231f1f;
}
.cls-709 {
  fill: #193c5a;
}
.cls-710 {
  fill: #2f2c2d;
}
.cls-711 {
  fill: #b91f2c;
}
.cls-712 {
  fill: #1a3a59;
}
.cls-713 {
  fill: #404041;
}
.cls-714 {
  fill: #b7222c;
}
.cls-715 {
  fill: #7897b0;
}
.cls-716 {
  fill: #7897b4;
}
.cls-717 {
  fill: #aad2de;
}
.cls-718 {
  fill: #aad3dc;
}
.cls-719 {
  fill: #443e3d;
}
.cls-720 {
  fill: #443e3c;
}
.cls-721 {
  fill: #d4e4e3;
}
.cls-722 {
  fill: #ecbe9f;
}
.cls-723 {
  fill: #dcb294;
}
.cls-724 {
  fill: #e6b796;
}
.cls-725 {
  fill: #efbb9d;
}
.cls-726 {
  fill: #ccb4b4;
}
.cls-727 {
  fill: #d47378;
}
.cls-728 {
  fill: #4294a2;
}
.cls-729 {
  fill: #c66569;
}
.cls-730 {
  fill: #c4bdbb;
}
.cls-731 {
  fill: #cab5af;
}
.cls-732 {
  fill: #636b69;
}
.cls-733 {
  fill: #d18088;
}
.cls-734 {
  fill: #fcfcfc;
}
.cls-735 {
  fill: #70533f;
}
.cls-736 {
  fill: #70584a;
}
.cls-737 {
  fill: #7ea0ab;
}
.cls-738 {
  fill: #ecbc99;
}
.cls-739 {
  fill: #565b55;
}
.cls-740 {
  fill: #93a750;
}
.cls-741 {
  fill: #9d9f58;
}
.cls-742 {
  fill: #f0e4c2;
}
.cls-743 {
  fill: #6a655f;
}
.cls-744 {
  fill: #515051;
}
.cls-745 {
  fill: #d71422;
}
.cls-746 {
  fill: #a6b055;
}
.cls-747 {
  fill: #cf1635;
}
.cls-748 {
  fill: #3f3f41;
}
.cls-749 {
  fill: #3b6890;
}
.cls-750 {
  fill: #446fa1;
}
.cls-751 {
  fill: #31516a;
}
.cls-752 {
  fill: #2b4a5e;
}
.cls-753 {
  fill: #e91c25;
}
.cls-754 {
  fill: #f2bf9a;
}
.cls-755 {
  fill: #a7a8ac;
}
.cls-756 {
  fill: #442d29;
}
.cls-757 {
  fill: #274456;
}
.cls-758 {
  fill: #859c5e;
}
.cls-759 {
  fill: #84995f;
}
.cls-760 {
  fill: #eb1c24;
}
.cls-761 {
  fill: #2c4a5c;
}
.cls-762 {
  fill: #8cc640;
}
.cls-763 {
  fill: #c1bcbc;
}
.cls-764 {
  fill: #099540;
}
.cls-765 {
  fill: #069344;
}
.cls-766 {
  fill: #0a933f;
}
.cls-767 {
  fill: #f6bf9c;
}
.cls-768 {
  fill: #a92e27;
}
.cls-769 {
  fill: #ce2932;
}
.cls-770 {
  fill: #202020;
}
.cls-771 {
  fill: #089348;
}
.cls-772 {
  fill: #bfd0ce;
}
.cls-773 {
  fill: #14632f;
}
.cls-774 {
  fill: #bdd1d1;
}
.cls-775 {
  fill: #b6c3b0;
}
.cls-776 {
  fill: #e2bf9f;
}
.cls-777 {
  fill: #b9c1b0;
}
.cls-778 {
  fill: #89c544;
}
.cls-779 {
  fill: #acc6b0;
}
.cls-780 {
  fill: #84c64a;
}
.cls-781 {
  fill: #17623f;
}
.cls-782 {
  fill: #dac7c6;
}
.cls-783 {
  fill: #e9c2a0;
}
.cls-784 {
  fill: #dad0d0;
}
.cls-785 {
  fill: #a1394e;
}
.cls-786 {
  fill: #87c05f;
}
.cls-787 {
  fill: #d7e3c4;
}
.cls-788 {
  fill: #89c15d;
}
.cls-789 {
  fill: #dac0c4;
}
.cls-790 {
  fill: #944053;
}
.cls-791 {
  fill: #d9c6c9;
}
.cls-792 {
  fill: #e0bec4;
}
.cls-793 {
  fill: #c7d3b1;
}
.cls-794 {
  fill: #8a363a;
}
.cls-795 {
  fill: #f4c09a;
}
.cls-796 {
  fill: #0c6336;
}
.cls-797 {
  fill: #eab896;
}
.cls-798 {
  fill: #4c2e28;
}
.cls-799 {
  fill: #bdbcbb;
}
.cls-800 {
  fill: #739ab6;
}
.cls-801 {
  fill: #4d4d4f;
}
.cls-802 {
  fill: #953643;
}
.cls-803 {
  fill: #d7cecb;
}
.cls-804 {
  fill: #9b626a;
}
.cls-805 {
  fill: #c7989d;
}
.cls-806 {
  fill: #c4bbb7;
}
.cls-807 {
  fill: #82a9d1;
}
.cls-808 {
  fill: #6b3445;
}
.cls-809 {
  fill: #1b5e34;
}
.cls-810 {
  fill: #573a37;
}
.cls-811 {
  fill: #cbb6bc;
}
.cls-812 {
  fill: #cc1836;
}
.cls-813 {
  fill: #979295;
}
.cls-814 {
  fill: #0a6639;
}
.cls-815 {
  fill: #bcbdbe;
}
.cls-816 {
  fill: #de843c;
}
.cls-817 {
  fill: #246c11;
}
.cls-818 {
  fill: #e58640;
}
.cls-819 {
  fill: #6a4237;
}
.cls-820 {
  fill: #1f4c61;
}
.cls-821 {
  fill: #e189b2;
}
.cls-822 {
  fill: #bcbdbf;
}
.cls-823 {
  fill: #cc7fa5;
}
.cls-824 {
  fill: #643d20;
}
.cls-825 {
  fill: #f0bb98;
}
.cls-826 {
  fill: #5b3613;
}
.cls-827 {
  fill: #eebb98;
}
.cls-828 {
  fill: #5c3514;
}
.cls-829 {
  fill: #ca80a4;
}
.cls-830 {
  fill: #f1b7d2;
}
.cls-831 {
  fill: #e4b695;
}
.cls-832 {
  fill: #e3b693;
}
.cls-833 {
  fill: #e3b799;
}
.cls-834 {
  fill: #c0d2cf;
}
.cls-835 {
  fill: #75563e;
}
.cls-836 {
  fill: #bed1d0;
}
.cls-837 {
  fill: #ebb6cb;
}
.cls-838 {
  fill: #4f3d2e;
}
.cls-839 {
  fill: #544437;
}
.cls-840 {
  fill: #a3988f;
}
.cls-841 {
  fill: #31261d;
}
.cls-842 {
  fill: #544126;
}
.cls-843 {
  fill: #555;
}
.cls-844 {
  fill: #5d82c0;
}
.cls-845 {
  fill: #eca986;
}
.cls-846 {
  fill: #d3dfd6;
}
.cls-847 {
  fill: #e35455;
}
.cls-848 {
  fill: #4a6ba5;
}
.cls-849 {
  fill: #535657;
}
.cls-850 {
  fill: #e25555;
}
.cls-851 {
  fill: #e2ae91;
}
.cls-852 {
  fill: #535556;
}
.cls-853 {
  fill: #cc5b57;
}
.cls-854 {
  fill: #e7d3d6;
}
.cls-855 {
  fill: #444343;
}
.cls-856 {
  fill: #7499f0;
}
.cls-857 {
  fill: #6990e7;
}
.cls-858 {
  fill: #fdb17e;
}
.cls-859 {
  fill: #bc3f3f;
}
.cls-860 {
  fill: #2f3b64;
}
.cls-861 {
  fill: #253863;
}
.cls-862 {
  fill: #223963;
}
.cls-863 {
  fill: #fab180;
}
.cls-864 {
  fill: #fab182;
}
.cls-865 {
  fill: #223965;
}
.cls-866 {
  fill: #5477c8;
}
.cls-867 {
  fill: #5077c8;
}
.cls-868 {
  fill: #eda97e;
}
.cls-869 {
  fill: #e9a87d;
}
.cls-870 {
  fill: #142769;
}
.cls-871 {
  fill: #12193c;
}
.cls-872 {
  fill: #203564;
}
.cls-873 {
  fill: #ae4b60;
}
.cls-874 {
  fill: #e99b57;
}
