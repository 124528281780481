.cls10-1 {
  fill: url(#Degradado_sin_nombre);
}
.cls10-2 {
  fill: #508992;
}
.cls10-3 {
  fill: #004346;
}
.cls10-4 {
  fill: #f2f2f2;
}
.cls10-5 {
  fill: #e6e6e6;
}
.cls10-6 {
  fill: #f7f9f9;
}
.cls10-17,
.cls10-25,
.cls10-29,
.cls10-7 {
  fill: none;
}
.cls10-7 {
  stroke: #491818;
}
.cls10-24,
.cls10-25,
.cls10-26,
.cls10-27,
.cls10-28,
.cls10-29,
.cls10-30,
.cls10-7 {
  stroke-miterlimit: 10;
}
.cls10-8 {
  fill: #172a39;
}
.cls10-9 {
  fill: #fff;
}
.cls10-10 {
  fill: #8a8580;
}
.cls10-11 {
  fill: #0abc8a;
}
.cls10-12,
.cls10-18 {
  fill: #75ddde;
}
.cls10-13 {
  fill: #a3a3a2;
}
.cls10-14 {
  fill: #323844;
}
.cls10-15 {
  fill: #556b07;
}
.cls10-16 {
  fill: #00aa4c;
}
.cls10-18 {
  opacity: 0.6;
  isolation: isolate;
}
.cls10-19 {
  fill: #c2b080;
}
.cls10-20,
.cls10-30 {
  fill: #b21b26;
}
.cls10-21,
.cls10-28 {
  fill: #f82326;
}
.cls10-22,
.cls10-26 {
  fill: #ce2028;
}
.cls10-23,
.cls10-27 {
  fill: #bf2134;
}
.cls10-24 {
  fill: #ff1e22;
  stroke: red;
  stroke-width: 2px;
}
.cls10-25 {
  stroke: #f82326;
}
.cls10-26,
.cls10-27,
.cls10-28,
.cls10-29,
.cls10-30 {
  stroke: aqua;
}
