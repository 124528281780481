.cls2-1 {
  fill: #4f9cea;
}
.cls2-2 {
  fill: #dde8f0;
}
.cls2-21,
.cls2-3 {
  fill: #fff;
}
.cls2-10,
.cls2-3 {
  stroke: #662d91;
}
.cls2-10,
.cls2-12,
.cls2-18,
.cls2-3 {
  stroke-miterlimit: 10;
}
.cls2-4 {
  fill: #013a67;
}
.cls2-5 {
  fill: #304b78;
}
.cls2-6 {
  fill: #2d325a;
}
.cls2-7 {
  fill: #3a4e7a;
}
.cls2-8 {
  fill: #0a0e29;
}
.cls2-9 {
  fill: #072446;
}
.cls2-10,
.cls2-16,
.cls2-18 {
  fill: none;
}
.cls2-11 {
  fill: #004581;
}
.cls2-12 {
  fill: #662d91;
  stroke: #3a4e7a;
}
.cls2-13 {
  fill: #f1f1f0;
}
.cls2-14 {
  fill: #0f6035;
}
.cls2-15 {
  fill: #72716d;
}
.cls2-17 {
  fill: #d27518;
}
.cls2-18 {
  stroke: #fff;
}
.cls2-19 {
  fill: #10508e;
}
.cls2-20 {
  fill: #76d219;
}
.cls2-22 {
  fill: #19d275;
}
.cls2-23 {
  fill: #781e27;
}
.cls2-24 {
  fill: #353e45;
}
.cls2-25 {
  fill: #a46263;
}
.cls2-26 {
  fill: #3a4d5b;
}
.cls2-27 {
  fill: url(#Degradado_sin_nombre);
}
.cls2-28 {
  fill: #35363a;
}
.cls2-29 {
  fill: #2d0b10;
}
.cls2-30 {
  fill: #77776d;
}
.cls2-31 {
  fill: #d2d21a;
}
.cls2-32 {
  fill: #4a4947;
}
.cls2-33 {
  fill: #c4c4c4;
}
.cls2-34 {
  fill: #8e8e85;
}
.cls2-35 {
  fill: #a8a8a1;
}
.cls2-36 {
  fill: #009245;
}
.cls2-37 {
  fill: #ed1c24;
}
