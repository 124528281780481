.content-inicio {
  background: #98d0fb;
}

.header {
  height: 160px;
  z-index: 99;
  position: fixed;
  top: 0;
  width: 70%;
  background: #013a67;
  left: 22%;
}

.title-h1 {
  color: white;
  font-size: 6.9rem;
}

.logo-Poli {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 10%;
  width: 170px;
}

.footer {
  height: 125px;
  z-index: 99;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 25px;
}
.content-reproductor {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.custom-btn {
  height: 8%;
  width: 18%;
  z-index: 999;
  position: fixed;
  top: 82.5%;
  right: 8%;
  color: white;
  font-size: 5rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
}

.button {
  background-image: url("../../assets/botones/boton-siguiente.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  margin-right: 15%;
}
.button:hover span {
  padding-right: 25px;
}
.button:hover span:after {
  opacity: 1;
  right: 0;
}

.content-regresar {
  width: 13%;
  display: flex;
  align-items: center;
}

.btn-regresar {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  width: 100%;
  height: 70%;
}

.buttonR {
  background-image: url("../../assets/botones/boton-regresar.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonR span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  left: 10%;
}
.buttonR:hover span {
  padding-left: 20px;
}
.buttonR:hover span:after {
  opacity: 1;
  right: 0;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .header {
    height: 88px;
    width: 72%;
    left: 21%;
  }

  .title-h1 {
    font-size: 3.5rem;
  }

  .logo-Poli {
    left: 10%;
    width: 105px;
  }

  .footer {
    height: 140px;
  }

  .boton-ingresar {
    top: 34%;
    font-size: 5rem;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .header {
    height: 120px;
    width: 72%;
    left: 21%;
  }

  .title-h1 {
    font-size: 5rem;
  }

  .logo-Poli {
    left: 10%;
    width: 140px;
  }

  .boton-ingresar {
    font-size: 7rem;
  }
}
