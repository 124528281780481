.content-text {
  background: #013a67;
  margin: 0 auto;
  height: 5rem;
  display: flex;
  align-items: center;
}

.body-ayuda {
  width: 100%;
  height: 60vh;
  display: flex;
  margin-top: 3em;
}

.contenedor-controles p {
  color: #fff;
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: normal;
}

.contenedor-controles {
  display: flex;
}

.ayuda-controles h2,
.ayuda-controles h3 {
  color: #fff;
  text-align: inherit;
}

.ayuda-controles h2 {
  font-size: 5rem;
}

.ayuda-controles h3 {
  font-size: 3rem;
  font-weight: normal;
  margin-top: 15px;
}

.content-text p {
  color: white;
  margin-left: 15px;
  font-size: 25px;
  font-weight: normal;
}

.contenedor-iconos {
  display: flex;
  justify-content: center;
}
.icon-teclado {
  width: 100%;
}

.icon-maus {
  width: 65%;
}

.ayuda-blog {
  margin-left: 25%;
  margin-top: 8%;
}

.btn-blog {
  height: 14%;
  width: 83%;
  color: white;
  font-size: 3rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
}

.icon-qr {
  width: 60%;
  margin: 45px 0 0 45px;
}
