.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 25px 25px;
  grid-auto-flow: row;
  grid-template-areas:
    ". decano Cordinador ."
    "programador1 programador2 disenador1 disenador2";
  width: 65vw;
  margin: 11% auto;
}

.decano {
  grid-area: decano;
}

.Cordinador {
  grid-area: Cordinador;
}

.programador1 {
  grid-area: programador1;
}

.programador2 {
  grid-area: programador2;
}

.disenador1 {
  grid-area: disenador1;
}

.disenador2 {
  grid-area: disenador2;
}

ul li a {
  color: #ebebeb;
}

ul li a:hover {
  color: #27262c;
}

section {
  background: #fff;
  border-radius: 0.25em;
  box-shadow: 0 0.15em 0.175em hsla(0, 0%, 0%, 0.1);
  margin: 1.5em;
  overflow: hidden;
}
figure {
  height: 17em;
  line-height: 35;
}
article {
  padding: 0 2em 2em 2em;
}

/* TYPOGRAPHY */
h2 {
  font-size: 2em;
  line-height: 1;
  margin: 0;
}
p {
  line-height: 1.4;
  font-size: 16px;
  text-align: center;
  margin: 0px;
  font-weight: bold;
}

@supports (display: grid) {
  @media only screen and (min-width: 768px) {
    main {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
      grid-gap: 1.5em;
      max-width: 1140px;
      padding: 1.5em;
    }

    section {
      margin: 0;
    }
  }
}

.cls4-1,
.cls4-11,
.cls4-13,
.cls4-30 {
  fill: none;
}
.cls4-2 {
  fill: #f4f6f8;
}
.cls4-3 {
  fill: #333;
}
.cls4-4 {
  fill: #7a87c2;
}
.cls4-5 {
  fill: #191a34;
}
.cls4-6 {
  fill: #233982;
}
.cls4-7 {
  fill: #e6ab31;
}
.cls4-8 {
  fill: #fdfdfb;
}
.cls4-9 {
  fill: #c62217;
}
.cls4-10 {
  fill: #f6e732;
}
.cls4-11 {
  stroke: #fff;
}
.cls4-11,
.cls4-13,
.cls4-30 {
  stroke-miterlimit: 10;
}
.cls4-12,
.cls4-19 {
  fill: #75747f;
}
.cls4-12 {
  opacity: 0.65;
}
.cls4-12,
.cls4-14,
.cls4-15,
.cls4-17,
.cls4-24 {
  isolation: isolate;
}
.cls4-13 {
  stroke: #acacb8;
}
.cls4-14,
.cls4-15,
.cls4-17 {
  opacity: 0.51;
}
.cls4-14 {
  fill: url(#Degradado_sin_nombre_3);
}
.cls4-15 {
  fill: url(#Degradado_sin_nombre_3-2);
}
.cls4-16 {
  fill: #abafbb;
}
.cls4-17 {
  fill: url(#Degradado_sin_nombre_3-3);
}
.cls4-18 {
  fill: #d4d7e3;
}
.cls4-20 {
  fill: #27252e;
}
.cls4-21 {
  fill: url(#Degradado_sin_nombre_4);
}
.cls4-22 {
  fill: #0e4b94;
}
.cls4-23 {
  clip-path: url(#clip-path);
}
.cls4-25 {
  clip-path: url(#clip-path-2);
}
.cls4-26 {
  clip-path: url(#clip-path-3);
}
.cls4-27 {
  clip-path: url(#clip-path-4);
}
.cls4-28 {
  clip-path: url(#clip-path-5);
}
.cls4-29 {
  clip-path: url(#clip-path-6);
}
.cls4-30 {
  stroke: #1b1464;
  stroke-width: 2px;
}
