.btn-paseo-sectores,
.btn-paseo-campus {
  height: 10%;
  width: 23%;
  z-index: 99;
  position: fixed;
  color: white;
  font-size: 3rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
}

.btn-paseo-sectores {
  top: 39%;
  right: 9%;
}

.btn-paseo-campus {
  top: 62%;
  right: 9%;
}

.content-nav {
  background: #013a67;
  width: 60%;
  margin: 0 auto;
  height: 5rem;
  display: flex;
}

.content-nav ul {
  display: flex;
  width: 100%;
  margin: auto;
  max-width: 1000px;
  justify-content: space-between;
  text-align: center;
}
.content-nav li {
  padding: 1rem 2rem 1.15rem;
  text-transform: uppercase;
  cursor: pointer;
  color: #ebebeb;
  min-width: 80px;
  margin: auto;
  font-size: 2rem;
}

.content-nav li:hover {
  background-image: url("https://scottyzen.sirv.com/Images/v/button.png");
  background-size: 100% 100%;
  color: #27262c;
  animation: spring 300ms ease-out;
  text-shadow: 0 -1px 0 #ef816c;
  font-weight: bold;
}
.content-nav li:active {
  transform: translateY(4px);
}

@keyframes spring {
  15% {
    -webkit-transform-origin: center center;
    -webkit-transform: scale(1.2, 1.1);
  }
  40% {
    -webkit-transform-origin: center center;
    -webkit-transform: scale(0.95, 0.95);
  }
  75% {
    -webkit-transform-origin: center center;
    -webkit-transform: scale(1.05, 1);
  }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: scale(1, 1);
  }
}

.cls3-1,
.cls3-14,
.cls3-210,
.cls3-217,
.cls3-25 {
  fill: none;
}
.cls3-2 {
  clip-path: url(#clip-path);
}
.cls3-3 {
  fill: #4f9cea;
}
.cls3-4 {
  fill: #666463;
}
.cls3-5 {
  fill: #76d219;
}
.cls3-6 {
  fill: #afa797;
}
.cls3-7 {
  fill: #aa2d29;
}
.cls3-8 {
  fill: #19d275;
}
.cls3-9 {
  fill: #0f6035;
}
.cls3-10 {
  fill: #e5b65a;
}
.cls3-11 {
  fill: #3e3d3f;
}
.cls3-12 {
  fill: #626161;
}
.cls3-13 {
  fill: #d27518;
}
.cls3-14 {
  stroke: #ed1e79;
}
.cls3-14,
.cls3-210,
.cls3-217,
.cls3-25 {
  stroke-miterlimit: 10;
}
.cls3-15 {
  fill: #6b1f2b;
}
.cls3-16 {
  fill: #9b3942;
}
.cls3-17 {
  fill: #212d3a;
}
.cls3-18 {
  fill: #7a8178;
}
.cls3-19 {
  fill: #bebcbc;
}
.cls3-20 {
  fill: #6c8194;
}
.cls3-21 {
  fill: #33445a;
}
.cls3-22 {
  fill: #3f3f3f;
}
.cls3-23 {
  fill: #595959;
}
.cls3-24 {
  fill: #8a8b8b;
}
.cls3-25 {
  stroke: lime;
}
.cls3-26 {
  fill: #9c9fa3;
}
.cls3-27 {
  fill: #a7a194;
}
.cls3-28 {
  fill: #0a2626;
}
.cls3-29 {
  fill: #247f4b;
}
.cls3-30 {
  fill: #161864;
}
.cls3-31 {
  clip-path: url(#clip-path-2);
}
.cls3-32 {
  clip-path: url(#clip-path-3);
}
.cls3-33 {
  isolation: isolate;
}
.cls3-34 {
  clip-path: url(#clip-path-4);
}
.cls3-35 {
  clip-path: url(#clip-path-5);
}
.cls3-36 {
  clip-path: url(#clip-path-6);
}
.cls3-37 {
  clip-path: url(#clip-path-7);
}
.cls3-38 {
  clip-path: url(#clip-path-8);
}
.cls3-39 {
  clip-path: url(#clip-path-9);
}
.cls3-40 {
  clip-path: url(#clip-path-10);
}
.cls3-41 {
  clip-path: url(#clip-path-11);
}
.cls3-42 {
  clip-path: url(#clip-path-12);
}
.cls3-43 {
  clip-path: url(#clip-path-13);
}
.cls3-44 {
  clip-path: url(#clip-path-14);
}
.cls3-45 {
  clip-path: url(#clip-path-15);
}
.cls3-46 {
  clip-path: url(#clip-path-16);
}
.cls3-47 {
  clip-path: url(#clip-path-17);
}
.cls3-48 {
  clip-path: url(#clip-path-18);
}
.cls3-49 {
  clip-path: url(#clip-path-19);
}
.cls3-50 {
  clip-path: url(#clip-path-20);
}
.cls3-51 {
  clip-path: url(#clip-path-21);
}
.cls3-52 {
  clip-path: url(#clip-path-22);
}
.cls3-53 {
  clip-path: url(#clip-path-23);
}
.cls3-54 {
  clip-path: url(#clip-path-24);
}
.cls3-55 {
  clip-path: url(#clip-path-25);
}
.cls3-56 {
  clip-path: url(#clip-path-26);
}
.cls3-57 {
  clip-path: url(#clip-path-27);
}
.cls3-58 {
  clip-path: url(#clip-path-28);
}
.cls3-59 {
  clip-path: url(#clip-path-29);
}
.cls3-60 {
  clip-path: url(#clip-path-30);
}
.cls3-61 {
  clip-path: url(#clip-path-31);
}
.cls3-62 {
  clip-path: url(#clip-path-32);
}
.cls3-63 {
  clip-path: url(#clip-path-33);
}
.cls3-64 {
  clip-path: url(#clip-path-34);
}
.cls3-65 {
  clip-path: url(#clip-path-35);
}
.cls3-66 {
  clip-path: url(#clip-path-36);
}
.cls3-67 {
  clip-path: url(#clip-path-37);
}
.cls3-68 {
  clip-path: url(#clip-path-38);
}
.cls3-69 {
  clip-path: url(#clip-path-39);
}
.cls3-70 {
  clip-path: url(#clip-path-40);
}
.cls3-71 {
  clip-path: url(#clip-path-41);
}
.cls3-72 {
  clip-path: url(#clip-path-42);
}
.cls3-73 {
  clip-path: url(#clip-path-43);
}
.cls3-74 {
  clip-path: url(#clip-path-44);
}
.cls3-75 {
  clip-path: url(#clip-path-45);
}
.cls3-76 {
  clip-path: url(#clip-path-46);
}
.cls3-77 {
  clip-path: url(#clip-path-47);
}
.cls3-78 {
  clip-path: url(#clip-path-48);
}
.cls3-79 {
  clip-path: url(#clip-path-49);
}
.cls3-80 {
  clip-path: url(#clip-path-50);
}
.cls3-81 {
  clip-path: url(#clip-path-51);
}
.cls3-82 {
  clip-path: url(#clip-path-52);
}
.cls3-83 {
  clip-path: url(#clip-path-53);
}
.cls3-84 {
  clip-path: url(#clip-path-54);
}
.cls3-85 {
  clip-path: url(#clip-path-55);
}
.cls3-86 {
  clip-path: url(#clip-path-56);
}
.cls3-87 {
  clip-path: url(#clip-path-57);
}
.cls3-88 {
  clip-path: url(#clip-path-58);
}
.cls3-89 {
  clip-path: url(#clip-path-59);
}
.cls3-90 {
  clip-path: url(#clip-path-60);
}
.cls3-91 {
  clip-path: url(#clip-path-61);
}
.cls3-92 {
  clip-path: url(#clip-path-62);
}
.cls3-93 {
  clip-path: url(#clip-path-63);
}
.cls3-94 {
  clip-path: url(#clip-path-64);
}
.cls3-95 {
  clip-path: url(#clip-path-65);
}
.cls3-96 {
  clip-path: url(#clip-path-66);
}
.cls3-97 {
  clip-path: url(#clip-path-67);
}
.cls3-98 {
  clip-path: url(#clip-path-68);
}
.cls3-99 {
  clip-path: url(#clip-path-69);
}
.cls3-100 {
  clip-path: url(#clip-path-70);
}
.cls3-101 {
  clip-path: url(#clip-path-71);
}
.cls3-102 {
  clip-path: url(#clip-path-72);
}
.cls3-103 {
  clip-path: url(#clip-path-73);
}
.cls3-104 {
  clip-path: url(#clip-path-74);
}
.cls3-105 {
  clip-path: url(#clip-path-75);
}
.cls3-106 {
  clip-path: url(#clip-path-76);
}
.cls3-107 {
  clip-path: url(#clip-path-77);
}
.cls3-108 {
  clip-path: url(#clip-path-78);
}
.cls3-109 {
  clip-path: url(#clip-path-79);
}
.cls3-110 {
  clip-path: url(#clip-path-80);
}
.cls3-111 {
  clip-path: url(#clip-path-81);
}
.cls3-112 {
  clip-path: url(#clip-path-82);
}
.cls3-113 {
  clip-path: url(#clip-path-83);
}
.cls3-114 {
  clip-path: url(#clip-path-84);
}
.cls3-115 {
  clip-path: url(#clip-path-85);
}
.cls3-116 {
  clip-path: url(#clip-path-86);
}
.cls3-117 {
  clip-path: url(#clip-path-87);
}
.cls3-118 {
  clip-path: url(#clip-path-88);
}
.cls3-119 {
  clip-path: url(#clip-path-89);
}
.cls3-120 {
  clip-path: url(#clip-path-90);
}
.cls3-121 {
  clip-path: url(#clip-path-91);
}
.cls3-122 {
  clip-path: url(#clip-path-92);
}
.cls3-123 {
  clip-path: url(#clip-path-93);
}
.cls3-124 {
  clip-path: url(#clip-path-94);
}
.cls3-125 {
  clip-path: url(#clip-path-95);
}
.cls3-126 {
  clip-path: url(#clip-path-96);
}
.cls3-127 {
  clip-path: url(#clip-path-97);
}
.cls3-128 {
  clip-path: url(#clip-path-98);
}
.cls3-129 {
  clip-path: url(#clip-path-99);
}
.cls3-130 {
  clip-path: url(#clip-path-100);
}
.cls3-131 {
  clip-path: url(#clip-path-101);
}
.cls3-132 {
  clip-path: url(#clip-path-102);
}
.cls3-133 {
  clip-path: url(#clip-path-103);
}
.cls3-134 {
  clip-path: url(#clip-path-104);
}
.cls3-135 {
  clip-path: url(#clip-path-105);
}
.cls3-136 {
  clip-path: url(#clip-path-106);
}
.cls3-137 {
  clip-path: url(#clip-path-107);
}
.cls3-138 {
  clip-path: url(#clip-path-108);
}
.cls3-139 {
  clip-path: url(#clip-path-109);
}
.cls3-140 {
  clip-path: url(#clip-path-110);
}
.cls3-141 {
  clip-path: url(#clip-path-111);
}
.cls3-142 {
  clip-path: url(#clip-path-112);
}
.cls3-143 {
  clip-path: url(#clip-path-113);
}
.cls3-144 {
  clip-path: url(#clip-path-114);
}
.cls3-145 {
  clip-path: url(#clip-path-115);
}
.cls3-146 {
  clip-path: url(#clip-path-116);
}
.cls3-147 {
  clip-path: url(#clip-path-117);
}
.cls3-148 {
  clip-path: url(#clip-path-118);
}
.cls3-149 {
  clip-path: url(#clip-path-119);
}
.cls3-150 {
  clip-path: url(#clip-path-120);
}
.cls3-151 {
  clip-path: url(#clip-path-121);
}
.cls3-152 {
  clip-path: url(#clip-path-122);
}
.cls3-153 {
  clip-path: url(#clip-path-123);
}
.cls3-154 {
  clip-path: url(#clip-path-124);
}
.cls3-155 {
  clip-path: url(#clip-path-125);
}
.cls3-156 {
  clip-path: url(#clip-path-127);
}
.cls3-157 {
  clip-path: url(#clip-path-128);
}
.cls3-158 {
  clip-path: url(#clip-path-129);
}
.cls3-159 {
  clip-path: url(#clip-path-130);
}
.cls3-160 {
  clip-path: url(#clip-path-131);
}
.cls3-161 {
  clip-path: url(#clip-path-132);
}
.cls3-162 {
  clip-path: url(#clip-path-133);
}
.cls3-163 {
  clip-path: url(#clip-path-134);
}
.cls3-164 {
  clip-path: url(#clip-path-135);
}
.cls3-165 {
  clip-path: url(#clip-path-136);
}
.cls3-166 {
  clip-path: url(#clip-path-137);
}
.cls3-167 {
  clip-path: url(#clip-path-138);
}
.cls3-168 {
  clip-path: url(#clip-path-139);
}
.cls3-169 {
  clip-path: url(#clip-path-140);
}
.cls3-170 {
  clip-path: url(#clip-path-141);
}
.cls3-171 {
  clip-path: url(#clip-path-142);
}
.cls3-172 {
  clip-path: url(#clip-path-143);
}
.cls3-173 {
  clip-path: url(#clip-path-144);
}
.cls3-174 {
  clip-path: url(#clip-path-145);
}
.cls3-175 {
  clip-path: url(#clip-path-146);
}
.cls3-176 {
  clip-path: url(#clip-path-147);
}
.cls3-177 {
  clip-path: url(#clip-path-148);
}
.cls3-178 {
  clip-path: url(#clip-path-149);
}
.cls3-179 {
  clip-path: url(#clip-path-150);
}
.cls3-180 {
  clip-path: url(#clip-path-151);
}
.cls3-181 {
  clip-path: url(#clip-path-152);
}
.cls3-182 {
  clip-path: url(#clip-path-153);
}
.cls3-183 {
  clip-path: url(#clip-path-154);
}
.cls3-184 {
  clip-path: url(#clip-path-155);
}
.cls3-185 {
  clip-path: url(#clip-path-156);
}
.cls3-186 {
  clip-path: url(#clip-path-157);
}
.cls3-187 {
  clip-path: url(#clip-path-158);
}
.cls3-188 {
  clip-path: url(#clip-path-159);
}
.cls3-189 {
  clip-path: url(#clip-path-160);
}
.cls3-190 {
  clip-path: url(#clip-path-161);
}
.cls3-191 {
  clip-path: url(#clip-path-162);
}
.cls3-192 {
  clip-path: url(#clip-path-163);
}
.cls3-193 {
  clip-path: url(#clip-path-164);
}
.cls3-194 {
  clip-path: url(#clip-path-165);
}
.cls3-195 {
  clip-path: url(#clip-path-166);
}
.cls3-196 {
  clip-path: url(#clip-path-167);
}
.cls3-197 {
  clip-path: url(#clip-path-168);
}
.cls3-198 {
  clip-path: url(#clip-path-169);
}
.cls3-199 {
  clip-path: url(#clip-path-170);
}
.cls3-200 {
  clip-path: url(#clip-path-171);
}
.cls3-201 {
  clip-path: url(#clip-path-172);
}
.cls3-202 {
  clip-path: url(#clip-path-173);
}
.cls3-203 {
  clip-path: url(#clip-path-174);
}
.cls3-204 {
  clip-path: url(#clip-path-175);
}
.cls3-205 {
  clip-path: url(#clip-path-176);
}
.cls3-206 {
  clip-path: url(#clip-path-177);
}
.cls3-207 {
  clip-path: url(#clip-path-178);
}
.cls3-208 {
  clip-path: url(#clip-path-179);
}
.cls3-209 {
  clip-path: url(#clip-path-180);
}
.cls3-210,
.cls3-217 {
  stroke: #111ec9;
}
.cls3-210 {
  stroke-width: 4px;
}
.cls3-211 {
  fill: #111ec9;
}
.cls3-212 {
  fill: #262221;
}
.cls3-213 {
  fill: #8b5940;
}
.cls3-214 {
  fill: #181818;
}
.cls3-215 {
  fill: #363030;
}
.cls3-216 {
  fill: #4a4645;
}
.cls3-218 {
  fill: #184746;
}
.cls3-219 {
  fill: #781e27;
}
.cls3-220 {
  fill: #009245;
}
