.btn-paseo-libre {
  height: 10%;
  width: 23%;
  z-index: 99;
  position: fixed;
  color: white;
  font-size: 5rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  top: 62%;
  right: 9%;
}

.cls4-1,
.cls4-12 {
  fill: none;
}
.cls4-2 {
  fill: url(#Degradado_sin_nombre);
}
.cls4-3 {
  clip-path: url(#clip-path);
}
.cls4-4 {
  fill: #dde8f0;
}
.cls4-26,
.cls4-5 {
  fill: #fff;
}
.cls4-12,
.cls4-5 {
  stroke: #662d91;
}
.cls4-12,
.cls4-14,
.cls4-19,
.cls4-22,
.cls4-25,
.cls4-26,
.cls4-27,
.cls4-29,
.cls4-30,
.cls4-32,
.cls4-33,
.cls4-38,
.cls4-5 {
  stroke-miterlimit: 10;
}
.cls4-6 {
  fill: #013a67;
}
.cls4-7 {
  fill: #304b78;
}
.cls4-8 {
  fill: #2d325a;
}
.cls4-9 {
  fill: #3a4e7a;
}
.cls4-10 {
  fill: #0a0e29;
}
.cls4-11 {
  fill: #072446;
}
.cls4-13 {
  fill: #004581;
}
.cls4-14 {
  fill: #662d91;
  stroke: #3a4e7a;
}
.cls4-15 {
  fill: #f1f1f0;
}
.cls4-16 {
  fill: #5f676c;
}
.cls4-17 {
  fill: #757575;
}
.cls4-18 {
  fill: #31550e;
}
.cls4-19 {
  fill: #fff1a1;
}
.cls4-19,
.cls4-22,
.cls4-25,
.cls4-26,
.cls4-27,
.cls4-29,
.cls4-30,
.cls4-32,
.cls4-33,
.cls4-38 {
  stroke: #000;
}
.cls4-20 {
  fill: #fff3a2;
}
.cls4-21 {
  fill: #930606;
}
.cls4-22 {
  fill: #b8b3ad;
}
.cls4-23 {
  fill: #d9ae82;
}
.cls4-24 {
  fill: #757672;
}
.cls4-25 {
  fill: #777776;
}
.cls4-27 {
  fill: #3d3125;
}
.cls4-28,
.cls4-33 {
  fill: #cdd1bb;
}
.cls4-29 {
  fill: #bfbfbf;
}
.cls4-30 {
  fill: #e8d08b;
}
.cls4-31 {
  fill: #bab5a7;
}
.cls4-32 {
  fill: #aeaeae;
}
.cls4-34 {
  fill: #f4d851;
}
.cls4-35 {
  fill: #cbcbcb;
}
.cls4-36 {
  fill: #c6c6c6;
}
.cls4-37 {
  fill: #c7c7c7;
}
.cls4-39 {
  fill: #434d4e;
}
.cls4-40 {
  fill: #560000;
}
.cls4-41 {
  fill: #910202;
}
.cls4-42 {
  fill: #7f0000;
}
.cls4-43 {
  fill: #e8724d;
}
.cls4-44 {
  fill: #ebd9d3;
}
.cls4-45 {
  fill: #ecd9d3;
}
.cls4-46 {
  fill: #eaa885;
}
.cls4-47 {
  fill: #eaa987;
}
.cls4-48 {
  fill: #eca988;
}
.cls4-49 {
  fill: #17333e;
}
.cls4-50 {
  fill: #353953;
}
.cls4-51 {
  fill: #443b4a;
}
.cls4-52 {
  fill: #1f3238;
}
.cls4-53 {
  fill: #e1af94;
}
.cls4-54 {
  fill: #e7aa8c;
}
.cls4-55 {
  fill: #cdbbb5;
}
.cls4-56 {
  fill: #3c394e;
}
.cls4-57 {
  fill: #c17f63;
}
.cls4-58 {
  fill: #323855;
}
.cls4-59 {
  fill: #eba886;
}
.cls4-60 {
  fill: #e87752;
}
.cls4-61 {
  fill: #e4744f;
}
.cls4-62 {
  fill: #ca6443;
}
.cls4-63 {
  fill: #16343e;
}
.cls4-64 {
  fill: #ebd9d4;
}
.cls4-65 {
  fill: #eed9d3;
}
.cls4-66 {
  fill: #d67a5d;
}
.cls4-67 {
  fill: #232437;
}
.cls4-68 {
  fill: #222437;
}
.cls4-69 {
  fill: #cf7a63;
}
.cls4-70 {
  fill: #eaa986;
}
.cls4-71 {
  fill: #c6b2ac;
}
.cls4-72 {
  fill: #50313b;
}
.cls4-73 {
  fill: #b47c5e;
}
.cls4-74 {
  fill: #eba885;
}
.cls4-75 {
  clip-path: url(#clip-path-2);
}
.cls4-76 {
  fill: #bcd0ce;
}
.cls4-77 {
  fill: #744c29;
}
.cls4-78 {
  fill: #499db1;
}
.cls4-79 {
  fill: #153952;
}
.cls4-80 {
  fill: #92a94c;
}
.cls4-81 {
  fill: #653a17;
}
.cls4-82 {
  fill: #d2e3e2;
}
.cls4-83 {
  fill: #5baac3;
}
.cls4-84 {
  fill: #a0ba4f;
}
.cls4-85 {
  fill: #343434;
}
.cls4-86 {
  fill: #4295a4;
}
.cls4-87 {
  fill: #be1e2d;
}
.cls4-88 {
  fill: #151314;
}
.cls4-89 {
  fill: #efbb97;
}
.cls4-90 {
  fill: #231f20;
}
.cls4-91 {
  fill: #323031;
}
.cls4-92 {
  fill: #ebb897;
}
.cls4-93 {
  fill: #ba1e2d;
}
.cls4-94 {
  fill: #231f1f;
}
.cls4-95 {
  fill: #193c5a;
}
.cls4-96 {
  fill: #2f2c2d;
}
.cls4-97 {
  fill: #b91f2c;
}
.cls4-98 {
  fill: #1a3a59;
}
.cls4-99 {
  fill: #404041;
}
.cls4-100 {
  fill: #b7222c;
}
.cls4-101 {
  fill: #7897b0;
}
.cls4-102 {
  fill: #7897b4;
}
.cls4-103 {
  fill: #aad2de;
}
.cls4-104 {
  fill: #aad3dc;
}
.cls4-105 {
  fill: #443e3d;
}
.cls4-106 {
  fill: #443e3c;
}
.cls4-107 {
  fill: #d4e4e3;
}
.cls4-108 {
  fill: #ecbe9f;
}
.cls4-109 {
  fill: #dcb294;
}
.cls4-110 {
  fill: #e6b796;
}
.cls4-111 {
  fill: #efbb9d;
}
.cls4-112 {
  fill: #ccb4b4;
}
.cls4-113 {
  fill: #d47378;
}
.cls4-114 {
  fill: #4294a2;
}
.cls4-115 {
  fill: #c66569;
}
.cls4-116 {
  fill: #c4bdbb;
}
.cls4-117 {
  fill: #cab5af;
}
.cls4-118 {
  fill: #636b69;
}
.cls4-119 {
  fill: #d18088;
}
.cls4-120 {
  fill: #70533f;
}
.cls4-121 {
  fill: #70584a;
}
.cls4-122 {
  fill: #7ea0ab;
}
.cls4-123 {
  fill: #ecbc99;
}
.cls4-124 {
  fill: #565b55;
}
.cls4-125 {
  fill: #93a750;
}
.cls4-126 {
  fill: #9d9f58;
}
.cls4-127 {
  fill: #f0e4c2;
}
.cls4-128 {
  fill: #6a655f;
}
.cls4-129 {
  fill: #515051;
}
.cls4-130 {
  fill: #d71422;
}
.cls4-131 {
  fill: #a6b055;
}
