.container-acerca {
  width: 100vw;
}
.container-acerca .card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 2rem;
  padding: 2rem;
  width: 74vw;
  overflow-y: auto;
  margin: 8% 20%;
}
.container-acerca .card-container .card {
  display: flex;
  align-items: center;
  height: 20rem;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: royalblue;
  border-radius: 5px;
}
.container-acerca .card-container .card img {
  height: 6rem;
  width: 6rem;
  margin: 3rem;
}
.container-acerca .card-container .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0 20px;
}
.container-acerca .card-container .text .name {
  font-weight: bold;
}

.container-acerca .name {
  font-size: 20px;
  text-align: center;
  margin-bottom: 15px;
}

.container-acerca .card-container .text .jurisdiction {
  font-size: 14px;
}

@media screen and (max-width: 500px) {
  .container-acerca .card-container {
    grid-template-columns: 100%;
  }
  .container-acerca .card-container .card {
    flex-direction: row;
    height: 10rem;
  }
  .container-acerca .card-container .text {
    align-items: flex-start;
  }
}
