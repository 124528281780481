.botones-museos {
  z-index: 99;
  position: fixed;
  top: 27%;
  left: 22%;
  width: 33%;
}

.botones-museos li {
  float: left;
  clear: left;
  width: 100%;
  margin: 0.2em 0;
  padding: 0.5em 0.8em;
  list-style: none;
  border-left: 5px solid #003842;
  cursor: pointer;
  color: #333;
  position: relative;
  z-index: 2;
}

.botones-museos ul:before {
  content: "museos";
  position: absolute;
  left: -2.5em;
  font-size: 3em;
  text-align: right;
  top: 1.5em;
  color: #013a67;
  font-weight: bold;
  font-family: "Maven Pro", sans-serif;
  transform: rotate(-90deg);
}

.botones-museos li {
  float: left;
  clear: left;
  width: 100%;
  margin: 0.5em 0;
  padding: 0.5em 0.8em;
  list-style: none;
  border-left: 5px solid #003842;
  cursor: pointer;
  color: #333;
  position: relative;
  z-index: 2;
}

.botones-museos li:hover {
  border-color: #00bde8 !important;
}

.botones-museos ul li a:hover {
  color: #fff;
}
.content-museos {
  display: flex;
  width: 70%;
}

.text-museo {
  background: #013a67;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.text-museo p {
  margin-left: 15px;
  font-size: 20px;
}

.icon-museos {
  width: 11%;
  margin-right: 15px;
}

.cls7-1 {
  fill: url(#Degradado_sin_nombre);
}
.cls7-2 {
  fill: #cdd1bb;
}
.cls7-3 {
  fill: #685c5e;
}
.cls7-4,
.cls7-41 {
  fill: #b2bdb5;
}
.cls7-4 {
  opacity: 0.6;
}
.cls7-16,
.cls7-4,
.cls7-41 {
  isolation: isolate;
}
.cls7-5 {
  fill: #f9f0c2;
}
.cls7-6 {
  fill: #c1a944;
}
.cls7-7 {
  fill: #f4d851;
}
.cls7-8 {
  fill: #31550e;
}
.cls7-9 {
  fill: #5f676c;
}
.cls7-10 {
  fill: #757575;
}
.cls7-11 {
  fill: none;
  stroke: #d4145a;
}
.cls7-11,
.cls7-44 {
  stroke-miterlimit: 10;
}
.cls7-12 {
  fill: #8d9095;
}
.cls7-13 {
  fill: #474344;
}
.cls7-14 {
  fill: #e8d08b;
}
.cls7-15 {
  fill: #fff1a1;
}
.cls7-16 {
  fill: #659faa;
  opacity: 0.8;
}
.cls7-17 {
  fill: #b8b3ad;
}
.cls7-18 {
  fill: #b02423;
}
.cls7-19 {
  fill: #bfd7db;
}
.cls7-20 {
  fill: #e0e0da;
}
.cls7-21,
.cls7-22,
.cls7-23,
.cls7-24,
.cls7-25,
.cls7-26,
.cls7-29,
.cls7-30,
.cls7-31,
.cls7-32,
.cls7-33,
.cls7-34,
.cls7-35,
.cls7-36,
.cls7-37,
.cls7-38,
.cls7-42,
.cls7-43,
.cls7-44,
.cls7-45,
.cls7-46,
.cls7-47,
.cls7-48,
.cls7-49,
.cls7-50,
.cls7-51,
.cls7-52,
.cls7-53,
.cls7-54,
.cls7-55,
.cls7-56,
.cls7-57,
.cls7-58,
.cls7-59,
.cls7-60,
.cls7-61,
.cls7-62,
.cls7-63,
.cls7-64,
.cls7-65,
.cls7-66,
.cls7-67,
.cls7-68,
.cls7-69,
.cls7-70,
.cls7-71,
.cls7-72,
.cls7-73,
.cls7-74,
.cls7-75,
.cls7-76,
.cls7-77,
.cls7-78,
.cls7-79 {
  fill: #bfbfbf;
  stroke: #000;
}
.cls7-21 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-22 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-23 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-24 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-25 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-26 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-27 {
  fill: #812a21;
}
.cls7-28 {
  fill: #bb3e20;
}
.cls7-29 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-30 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-31 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-32 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-33 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-34 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-35 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-36 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-37 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-38 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-39 {
  fill: #c4b882;
}
.cls7-40 {
  fill: #e0d192;
}
.cls7-41 {
  opacity: 0.4;
}
.cls7-42 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-43 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-44 {
  stroke-width: 0.25px;
}
.cls7-45 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-46 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-47 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-48 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-49 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-50 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-51 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-52 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-53 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-54 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-55 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-56 {
  stroke-miterlimit: 10.04;
  stroke-width: 0.25px;
}
.cls7-57 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-58 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-59 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-60 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-61 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-62 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-63 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-64 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-65 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-66 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-67 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-68 {
  stroke-miterlimit: 10.01;
  stroke-width: 0.25px;
}
.cls7-69 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-70 {
  stroke-miterlimit: 10.02;
  stroke-width: 0.25px;
}
.cls7-71 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-72 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-73 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-74 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-75 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-76 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-77 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-78 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-79 {
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}
.cls7-80 {
  fill: #e76429;
}
