.btn-paseo-virtual,
.btn-museo-virtual {
  height: 10%;
  top: 54%;
  z-index: 99;
  position: fixed;
  color: white;
  font-size: 4rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
}

.btn-paseo-virtual {
  width: 23%;
  right: 6%;
}

.btn-museo-virtual {
  width: 22%;
  left: 3.3%;
}
