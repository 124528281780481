.btn-museos {
  height: 10%;
  width: 23%;
  z-index: 99;
  position: fixed;
  color: white;
  font-size: 5rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  top: 50%;
  right: 9%;
}

.cls8-1 {
  fill: url(#Degradado_sin_nombre);
}
.cls8-2 {
  fill: #508992;
}
.cls8-3 {
  fill: #004346;
}
.cls8-4 {
  fill: #f2f2f2;
}
.cls8-5 {
  fill: #e6e6e6;
}
.cls8-6 {
  fill: #f7f9f9;
}
.cls8-17,
.cls8-24,
.cls8-30,
.cls8-7 {
  fill: none;
}
.cls8-7 {
  stroke: #491818;
}
.cls8-20,
.cls8-24,
.cls8-27,
.cls8-28,
.cls8-29,
.cls8-30,
.cls8-7 {
  stroke-miterlimit: 10;
}
.cls8-8 {
  fill: #172a39;
}
.cls8-9 {
  fill: #fff;
}
.cls8-10 {
  fill: #8a8580;
}
.cls8-11 {
  fill: #0abc8a;
}
.cls8-12,
.cls8-18 {
  fill: #75ddde;
}
.cls8-13 {
  fill: #a3a3a2;
}
.cls8-14 {
  fill: #323844;
}
.cls8-15 {
  fill: #556b07;
}
.cls8-16 {
  fill: #00aa4c;
}
.cls8-18 {
  opacity: 0.6;
  isolation: isolate;
}
.cls8-19 {
  fill: #f82326;
}
.cls8-20 {
  fill: #e01b25;
}
.cls8-20,
.cls8-27,
.cls8-28,
.cls8-29 {
  stroke: #fcee21;
}
.cls8-21 {
  fill: #ce2028;
}
.cls8-22,
.cls8-27 {
  fill: #322f33;
}
.cls8-23 {
  fill: #b21b26;
}
.cls8-24 {
  stroke: red;
}
.cls8-25,
.cls8-28 {
  fill: #9c999b;
}
.cls8-26 {
  fill: #413d43;
}
.cls8-29 {
  fill: #ff1e22;
  stroke-width: 3px;
}
.cls8-30 {
  stroke: aqua;
}
.cls8-31 {
  fill: url(#Degradado_sin_nombre_5);
}
.cls8-32 {
  fill: url(#Degradado_sin_nombre_5-2);
}
.cls8-33 {
  fill: url(#Degradado_sin_nombre_5-3);
}
.cls8-34 {
  fill: url(#Degradado_sin_nombre_5-4);
}
