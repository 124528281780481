:root {
  --blanco: #ffffff;
  --gris1: #edf2f6;
  --gris2: #2f3848;
  --gris3: #1a202d;
  --negro: #000000;
  --borde: #0075ff;
  --error: #bb2929;
  --exito: #1ed12d;
  /* --background-gradient: linear-gradient(30deg, #f4d7aa 30%, #f4d7aa); */
  --gray: #34495e;
  --darkgray: #2c3e50;
  --headingFont: "Raleway", sans-serif;
  --textFont: "Roboto", sans-serif;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
:focus-visible {
  outline: none;
}
html {
  box-sizing: border-box;
  height: 100%;
  font-size: 62.5%;
}
body {
  min-height: 100%;
  background-color: var(--gris1);
  font-family: var(--textFont);
  padding: 0;
  margin: 0;
}

h1,
h2 {
  font-family: var(--headingFont);
  text-align: center;
  color: var(--gris2);
}
h1 span {
  font-weight: 400;
}
h1 {
  font-size: 3rem;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  color: var(--gris2);
}
