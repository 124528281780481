.cls9-1 {
  fill: url(#Degradado_sin_nombre);
}
.cls9-2 {
  fill: #508992;
}
.cls9-3 {
  fill: #004346;
}
.cls9-4 {
  fill: #f2f2f2;
}
.cls9-5 {
  fill: #e6e6e6;
}
.cls9-6 {
  fill: #f7f9f9;
}
.cls9-17,
.cls9-23,
.cls9-7 {
  fill: none;
}
.cls9-7 {
  stroke: #491818;
}
.cls9-23,
.cls9-24,
.cls9-25,
.cls9-27,
.cls9-29,
.cls9-30,
.cls9-7 {
  stroke-miterlimit: 10;
}
.cls9-8 {
  fill: #172a39;
}
.cls9-9 {
  fill: #fff;
}
.cls9-10 {
  fill: #8a8580;
}
.cls9-11 {
  fill: #0abc8a;
}
.cls9-12,
.cls9-18 {
  fill: #75ddde;
}
.cls9-13 {
  fill: #a3a3a2;
}
.cls9-14 {
  fill: #323844;
}
.cls9-15 {
  fill: #556b07;
}
.cls9-16 {
  fill: #00aa4c;
}
.cls9-18 {
  opacity: 0.6;
  isolation: isolate;
}
.cls9-19 {
  fill: #c2b080;
}
.cls9-20 {
  fill: #f82326;
}
.cls9-21 {
  fill: #413d43;
}
.cls9-22,
.cls9-30 {
  fill: #322f33;
}
.cls9-23,
.cls9-29 {
  stroke: red;
}
.cls9-24,
.cls9-29 {
  fill: #ff1e22;
}
.cls9-24,
.cls9-25,
.cls9-27,
.cls9-30 {
  stroke: #fcee21;
}
.cls9-25,
.cls9-28 {
  fill: #b21b26;
}
.cls9-26,
.cls9-27 {
  fill: #ce2028;
}
.cls9-31 {
  fill: #9c999b;
}
.cls9-32 {
  fill: aqua;
}
.cls9-33 {
  fill: red;
}
