.botones-sectores {
  z-index: 99;
  position: fixed;
  top: 20%;
  left: 22%;
  width: 33%;
}

.botones-sectores ul {
  float: left;
  width: 100%;
  margin: 2em 0;
  padding: 0;
  position: relative;
  display: block;
}

.botones-sectores ul:before {
  content: "paseos";
  position: absolute;
  left: -2.5em;
  font-size: 3em;
  text-align: right;
  top: 1.3em;
  color: #013a67;
  font-weight: bold;
  font-family: "Maven Pro", sans-serif;
  transform: rotate(-90deg);
}

.botones-sectores li {
  float: left;
  clear: left;
  width: 100%;
  margin: 0.2em 0;
  padding: 0.5em 0.8em;
  list-style: none;
  border-left: 5px solid #003842;
  cursor: pointer;
  color: #333;
  position: relative;
  z-index: 2;
}

.botones-sectores li:hover {
  border-color: #00bde8 !important;
}

.zone-1 {
  background: #00bde857;
  border-radius: 99px;
  padding: 2px 16px;
  font-weight: bold;
  color: #287283;
}

.in {
  display: flex;
  justify-content: space-between;
  height: 42px;
  align-items: center;
}

.medicina,
.fie,
.central,
.recursos1,
.recursos2,
.laboratorio,
.pecuarias,
.ciencias,
.dtics,
.mecanica,
.auditoria,
.fade {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.medicina {
  background-image: url("../../assets/botones/medicina.svg");
}

.fie {
  background-image: url("../../assets/botones/fie.svg");
}

.central {
  background-image: url("../../assets/botones/edificio-central.svg");
}

.recursos1 {
  background-image: url("../../assets/botones/recursos-naturales-1.svg");
}

.recursos2 {
  background-image: url("../../assets/botones/recursos-naturales-2.svg");
}

.laboratorio {
  background-image: url("../../assets/botones/laboratorios.svg");
}

.pecuarias {
  background-image: url("../../assets/botones/pecuarias.svg");
}

.ciencias {
  background-image: url("../../assets/botones/ciencias.svg");
}

.dtics {
  background-image: url("../../assets/botones/dtics.svg");
}

.mecanica {
  background-image: url("../../assets/botones/mecanica.svg");
}

.auditoria {
  background-image: url("../../assets/botones/entrada.svg");
}

.fade {
  background-image: url("../../assets/botones/fade.svg");
}

.cls5-1,
.cls5-44 {
  fill: url(#Degradado_sin_nombre_2);
}
.cls5-2 {
  fill: #013a67;
}
.cls5-3 {
  fill: #304b78;
}
.cls5-4 {
  fill: #dde8f0;
}
.cls5-1002,
.cls5-5 {
  fill: #fff;
}
.cls5-5,
.cls5-9 {
  stroke: #662d91;
}
.cls5-32,
.cls5-35,
.cls5-42,
.cls5-43,
.cls5-5,
.cls5-9,
.cls5-988,
.cls5-989,
.cls5-990,
.cls5-991,
.cls5-992,
.cls5-993,
.cls5-994,
.cls5-995,
.cls5-996,
.cls5-997,
.cls5-998,
.cls5-999 {
  stroke-miterlimit: 10;
}
.cls5-6 {
  fill: #3a4e7a;
}
.cls5-7 {
  fill: #0a0e29;
}
.cls5-8 {
  fill: #072446;
}
.cls5-32,
.cls5-35,
.cls5-42,
.cls5-43,
.cls5-9,
.cls5-988 {
  fill: none;
}
.cls5-10 {
  fill: #004581;
}
.cls5-11 {
  fill: #f1f1f0;
}
.cls5-12 {
  fill: #4a605d;
}
.cls5-13 {
  fill: #be6b4b;
}
.cls5-14 {
  fill: #d1c4bc;
}
.cls5-15 {
  fill: #5e6263;
}
.cls5-16 {
  fill: #f0f1ec;
}
.cls5-17 {
  fill: #fdfaf3;
}
.cls5-18 {
  fill: #d0d0c8;
}
.cls5-19 {
  fill: #78644c;
}
.cls5-20 {
  fill: #853921;
}
.cls5-21 {
  fill: #0b0000;
}
.cls5-22 {
  fill: #743929;
}
.cls5-23 {
  fill: #0d1613;
}
.cls5-24 {
  fill: #b1b2ac;
}
.cls5-25 {
  fill: #240900;
}
.cls5-26 {
  fill: #b9c1c3;
}
.cls5-27 {
  fill: #dcddd8;
}
.cls5-28 {
  fill: #51534e;
}
.cls5-29 {
  fill: #939b8e;
}
.cls5-30 {
  fill: #8799ad;
}
.cls5-31 {
  fill: #d8e3d5;
}
.cls5-32,
.cls5-43 {
  stroke: #4d4d4d;
}
.cls5-32 {
  stroke-width: 2px;
}
.cls5-33 {
  fill: #322920;
}
.cls5-34 {
  fill: #f0e8d5;
}
.cls5-35 {
  stroke: #93278f;
}
.cls5-36 {
  fill: #010204;
}
.cls5-37 {
  fill: #515449;
}
.cls5-38 {
  fill: #93523e;
}
.cls5-39 {
  fill: #c5d6cc;
}
.cls5-40 {
  fill: #787f78;
}
.cls5-41 {
  fill: #201d16;
}
.cls5-42 {
  stroke: #b3b3b3;
}
.cls5-42,
.cls5-43 {
  stroke-width: 3px;
}
.cls5-44 {
  opacity: 0.61;
}
.cls5-44,
.cls5-989,
.cls5-990,
.cls5-991,
.cls5-992,
.cls5-993,
.cls5-994,
.cls5-995,
.cls5-996,
.cls5-997,
.cls5-998,
.cls5-999 {
  isolation: isolate;
}
.cls5-45 {
  fill: #696762;
}
.cls5-46 {
  fill: #a48488;
}
.cls5-47 {
  fill: #ad8b69;
}
.cls5-48 {
  fill: #bd9265;
}
.cls5-49 {
  fill: #547e2b;
}
.cls5-50 {
  fill: #9c7a4c;
}
.cls5-51 {
  fill: #767668;
}
.cls5-52 {
  fill: #b8949e;
}
.cls5-53 {
  fill: #9d804c;
}
.cls5-54 {
  fill: #c19498;
}
.cls5-55 {
  fill: #c4ba9b;
}
.cls5-56 {
  fill: #878b6e;
}
.cls5-57 {
  fill: #c6b29e;
}
.cls5-58 {
  fill: #b9aa99;
}
.cls5-59 {
  fill: #717b2d;
}
.cls5-60 {
  fill: #bcaca4;
}
.cls5-61 {
  fill: #b7a79a;
}
.cls5-62 {
  fill: #7b6a6f;
}
.cls5-63 {
  fill: #bab4b1;
}
.cls5-64 {
  fill: #d2c0bf;
}
.cls5-65 {
  fill: #432e2b;
}
.cls5-66 {
  fill: #6c7c26;
}
.cls5-67 {
  fill: #bfbfbb;
}
.cls5-68 {
  fill: #a29f66;
}
.cls5-69 {
  fill: #833941;
}
.cls5-70 {
  fill: #81312c;
}
.cls5-71 {
  fill: #6f846d;
}
.cls5-72 {
  fill: #718569;
}
.cls5-73 {
  fill: #70856e;
}
.cls5-74 {
  fill: #965f8b;
}
.cls5-75 {
  fill: #b59154;
}
.cls5-76 {
  fill: #ad8960;
}
.cls5-77 {
  fill: #5e6160;
}
.cls5-78 {
  fill: #318056;
}
.cls5-79 {
  fill: #71836d;
}
.cls5-80 {
  fill: #857747;
}
.cls5-81 {
  fill: #b58d5b;
}
.cls5-82 {
  fill: #4e8e61;
}
.cls5-83 {
  fill: #a54a9e;
}
.cls5-84 {
  fill: #4b6d52;
}
.cls5-85 {
  fill: #4d7544;
}
.cls5-86 {
  fill: #347d73;
}
.cls5-87 {
  fill: #708e72;
}
.cls5-88 {
  fill: #55637d;
}
.cls5-89 {
  fill: #586480;
}
.cls5-90 {
  fill: #826444;
}
.cls5-91 {
  fill: #6fa9a0;
}
.cls5-92 {
  fill: #a99868;
}
.cls5-93 {
  fill: #609a75;
}
.cls5-94 {
  fill: #8ba273;
}
.cls5-95 {
  fill: #61afad;
}
.cls5-96 {
  fill: #615d8a;
}
.cls5-97 {
  fill: #a89466;
}
.cls5-98 {
  fill: #507069;
}
.cls5-99 {
  fill: #985dac;
}
.cls5-100 {
  fill: #9092b6;
}
.cls5-101 {
  fill: #ca9164;
}
.cls5-102 {
  fill: #62875e;
}
.cls5-103 {
  fill: #724182;
}
.cls5-104 {
  fill: #9e92c5;
}
.cls5-105 {
  fill: #cb946b;
}
.cls5-106 {
  fill: #648c63;
}
.cls5-107 {
  fill: #596865;
}
.cls5-108 {
  fill: #50714c;
}
.cls5-109 {
  fill: #9e989e;
}
.cls5-110 {
  fill: #3d6669;
}
.cls5-111 {
  fill: #545d80;
}
.cls5-112 {
  fill: #9ea272;
}
.cls5-113 {
  fill: #7d99b2;
}
.cls5-114 {
  fill: #52675a;
}
.cls5-115 {
  fill: #a69a9f;
}
.cls5-116 {
  fill: #728350;
}
.cls5-117 {
  fill: #90a457;
}
.cls5-118 {
  fill: #aa9d64;
}
.cls5-119 {
  fill: #9baa5b;
}
.cls5-120 {
  fill: #734c85;
}
.cls5-121 {
  fill: #66b29c;
}
.cls5-122 {
  fill: #8f9a69;
}
.cls5-123 {
  fill: #64b066;
}
.cls5-124 {
  fill: #9dac6a;
}
.cls5-125 {
  fill: #73a6b9;
}
.cls5-126 {
  fill: #a6a471;
}
.cls5-127 {
  fill: #83a1b4;
}
.cls5-128 {
  fill: #b8a46b;
}
.cls5-129 {
  fill: #98aa5c;
}
.cls5-130 {
  fill: #99a655;
}
.cls5-131 {
  fill: #7da19b;
}
.cls5-132 {
  fill: #6ca6a0;
}
.cls5-133 {
  fill: #6eb396;
}
.cls5-134 {
  fill: #9797a3;
}
.cls5-135 {
  fill: #7fa4b8;
}
.cls5-136 {
  fill: #aea86b;
}
.cls5-137 {
  fill: #664b8b;
}
.cls5-138 {
  fill: #98b06d;
}
.cls5-139 {
  fill: #58722f;
}
.cls5-140 {
  fill: #2d814d;
}
.cls5-141 {
  fill: #377520;
}
.cls5-142 {
  fill: #7e9677;
}
.cls5-143 {
  fill: #7f5f88;
}
.cls5-144 {
  fill: #63a498;
}
.cls5-145 {
  fill: #548b57;
}
.cls5-146 {
  fill: #625585;
}
.cls5-147 {
  fill: #536276;
}
.cls5-148 {
  fill: #526a1a;
}
.cls5-149 {
  fill: #848eb0;
}
.cls5-150 {
  fill: #ad9473;
}
.cls5-151 {
  fill: #73a8ab;
}
.cls5-152 {
  fill: #739d63;
}
.cls5-153 {
  fill: #9691c4;
}
.cls5-154 {
  fill: #9196ba;
}
.cls5-155 {
  fill: #575c7e;
}
.cls5-156 {
  fill: #6b5194;
}
.cls5-157 {
  fill: #4f8366;
}
.cls5-158 {
  fill: #614d6b;
}
.cls5-159 {
  fill: #a47f38;
}
.cls5-160 {
  fill: #565978;
}
.cls5-161 {
  fill: #9caf6b;
}
.cls5-162 {
  fill: #725285;
}
.cls5-163 {
  fill: #91ac5c;
}
.cls5-164 {
  fill: #6aaea9;
}
.cls5-165 {
  fill: #969e5c;
}
.cls5-166 {
  fill: #9ca95c;
}
.cls5-167 {
  fill: #7aaba3;
}
.cls5-168 {
  fill: #859d98;
}
.cls5-169 {
  fill: #8ba960;
}
.cls5-170 {
  fill: #849e98;
}
.cls5-171 {
  fill: #9bb07e;
}
.cls5-172 {
  fill: #9fa95d;
}
.cls5-173 {
  fill: #a3a65e;
}
.cls5-174 {
  fill: #308155;
}
.cls5-175 {
  fill: #bebcbc;
}
.cls5-176 {
  fill: #c1bfb2;
}
.cls5-177 {
  fill: #b9a18e;
}
.cls5-178 {
  fill: #bfae91;
}
.cls5-179 {
  fill: #777374;
}
.cls5-180 {
  fill: #5e5d54;
}
.cls5-181 {
  fill: #8f7f70;
}
.cls5-182 {
  fill: #847e5a;
}
.cls5-183 {
  fill: #9f9f56;
}
.cls5-184 {
  fill: #9f8a80;
}
.cls5-185 {
  fill: #d59a68;
}
.cls5-186 {
  fill: #98273f;
}
.cls5-187 {
  fill: #a6804c;
}
.cls5-188 {
  fill: #aeaa9e;
}
.cls5-189 {
  fill: #a29f70;
}
.cls5-190 {
  fill: #a8a45a;
}
.cls5-191 {
  fill: #80a056;
}
.cls5-192 {
  fill: #a14c48;
}
.cls5-193 {
  fill: #6e6e6c;
}
.cls5-194 {
  fill: #503c37;
}
.cls5-195 {
  fill: #aaa69c;
}
.cls5-196 {
  fill: #559947;
}
.cls5-197 {
  fill: #7e8a73;
}
.cls5-198 {
  fill: #51803c;
}
.cls5-199 {
  fill: #76a97d;
}
.cls5-200 {
  fill: #977e65;
}
.cls5-201 {
  fill: #9f8769;
}
.cls5-202 {
  fill: #d2bf99;
}
.cls5-203 {
  fill: #c28f6c;
}
.cls5-204 {
  fill: #75935e;
}
.cls5-205 {
  fill: #659d54;
}
.cls5-206 {
  fill: #c7b29b;
}
.cls5-207 {
  fill: #b6a16f;
}
.cls5-208 {
  fill: #d8b08e;
}
.cls5-209 {
  fill: #5a714f;
}
.cls5-210 {
  fill: #934b1f;
}
.cls5-211 {
  fill: #a48048;
}
.cls5-212 {
  fill: #baba9d;
}
.cls5-213 {
  fill: #764337;
}
.cls5-214 {
  fill: #588442;
}
.cls5-215 {
  fill: #b2b9a1;
}
.cls5-216 {
  fill: #5d662d;
}
.cls5-217 {
  fill: #8a8668;
}
.cls5-218 {
  fill: #913430;
}
.cls5-219 {
  fill: #c2b092;
}
.cls5-220 {
  fill: #52732f;
}
.cls5-221 {
  fill: #a9a782;
}
.cls5-222 {
  fill: #a9a89b;
}
.cls5-223 {
  fill: #b6805a;
}
.cls5-224 {
  fill: #a13b35;
}
.cls5-225 {
  fill: #c0a457;
}
.cls5-226 {
  fill: #8b3b24;
}
.cls5-227 {
  fill: #9c3638;
}
.cls5-228 {
  fill: #95a1ae;
}
.cls5-229 {
  fill: #b37555;
}
.cls5-230 {
  fill: #c7ae74;
}
.cls5-231 {
  fill: #39782e;
}
.cls5-232 {
  fill: #9c99b1;
}
.cls5-233 {
  fill: #a3ac63;
}
.cls5-234 {
  fill: #8d3893;
}
.cls5-235 {
  fill: #9b4643;
}
.cls5-236 {
  fill: #839798;
}
.cls5-237 {
  fill: #a47f44;
}
.cls5-238 {
  fill: #6fa95d;
}
.cls5-239 {
  fill: #993736;
}
.cls5-240 {
  fill: #a83034;
}
.cls5-241 {
  fill: #854c2d;
}
.cls5-242 {
  fill: #bea270;
}
.cls5-243 {
  fill: #a89354;
}
.cls5-244 {
  fill: #8d8272;
}
.cls5-245 {
  fill: #5e886d;
}
.cls5-246 {
  fill: #628c5d;
}
.cls5-247 {
  fill: #bac174;
}
.cls5-248 {
  fill: #c3b062;
}
.cls5-249 {
  fill: #77966c;
}
.cls5-250 {
  fill: #799185;
}
.cls5-251 {
  fill: #bdb799;
}
.cls5-252 {
  fill: #939a72;
}
.cls5-253 {
  fill: #a6a882;
}
.cls5-254 {
  fill: #448430;
}
.cls5-255 {
  fill: #aab294;
}
.cls5-256 {
  fill: #c3a49b;
}
.cls5-257 {
  fill: #8e9a70;
}
.cls5-258 {
  fill: #b79979;
}
.cls5-259 {
  fill: #b84f5c;
}
.cls5-260 {
  fill: #ac9f87;
}
.cls5-261 {
  fill: #c09879;
}
.cls5-262 {
  fill: #9f4053;
}
.cls5-263 {
  fill: #bfa79f;
}
.cls5-264 {
  fill: #c5949c;
}
.cls5-265 {
  fill: #6e9164;
}
.cls5-266 {
  fill: #8b3848;
}
.cls5-267 {
  fill: #996031;
}
.cls5-268 {
  fill: #c79ea1;
}
.cls5-269 {
  fill: #d79488;
}
.cls5-270 {
  fill: #a1724c;
}
.cls5-271 {
  fill: #78847d;
}
.cls5-272 {
  fill: #a42d43;
}
.cls5-273 {
  fill: #6f9581;
}
.cls5-274 {
  fill: #7d8f7b;
}
.cls5-275 {
  fill: #a27468;
}
.cls5-276 {
  fill: #894e32;
}
.cls5-277 {
  fill: #86402b;
}
.cls5-278 {
  fill: #b02930;
}
.cls5-279 {
  fill: #854534;
}
.cls5-280 {
  fill: #ab423e;
}
.cls5-281 {
  fill: #8c4133;
}
.cls5-282 {
  fill: #aa5264;
}
.cls5-283 {
  fill: #c07b78;
}
.cls5-284 {
  fill: #453426;
}
.cls5-285 {
  fill: #be594f;
}
.cls5-286 {
  fill: #cfc1c3;
}
.cls5-287 {
  fill: #8f8564;
}
.cls5-288 {
  fill: #b29d9d;
}
.cls5-289 {
  fill: #638e4b;
}
.cls5-290 {
  fill: #bebf76;
}
.cls5-291 {
  fill: #868b67;
}
.cls5-292 {
  fill: #518845;
}
.cls5-293 {
  fill: #538146;
}
.cls5-294 {
  fill: #4e8034;
}
.cls5-295 {
  fill: #5d7c44;
}
.cls5-296 {
  fill: #2e7d30;
}
.cls5-297 {
  fill: #7fa471;
}
.cls5-298 {
  fill: #a0323d;
}
.cls5-299 {
  fill: #bab297;
}
.cls5-300 {
  fill: #a4a995;
}
.cls5-301 {
  fill: #b2a084;
}
.cls5-302 {
  fill: #b8b26e;
}
.cls5-303 {
  fill: #7d8c70;
}
.cls5-304 {
  fill: #a3b290;
}
.cls5-305 {
  fill: #982844;
}
.cls5-306 {
  fill: #827b3b;
}
.cls5-307 {
  fill: #af242e;
}
.cls5-308 {
  fill: #a1232e;
}
.cls5-309 {
  fill: #8f503b;
}
.cls5-310 {
  fill: #afaa7a;
}
.cls5-311 {
  fill: #418340;
}
.cls5-312 {
  fill: #b0b09e;
}
.cls5-313 {
  fill: #a13332;
}
.cls5-314 {
  fill: #b3a498;
}
.cls5-315 {
  fill: #a2815c;
}
.cls5-316 {
  fill: #b3a493;
}
.cls5-317 {
  fill: #943c28;
}
.cls5-318 {
  fill: #9c3b2d;
}
.cls5-319 {
  fill: #9bb98a;
}
.cls5-320 {
  fill: #517d5a;
}
.cls5-321 {
  fill: #edbcc8;
}
.cls5-322 {
  fill: #e6b8c2;
}
.cls5-323 {
  fill: #d8bd85;
}
.cls5-324 {
  fill: #979750;
}
.cls5-325 {
  fill: #8aa960;
}
.cls5-326 {
  fill: #aaa085;
}
.cls5-327 {
  fill: #80916a;
}
.cls5-328 {
  fill: #9f3651;
}
.cls5-329 {
  fill: #93394c;
}
.cls5-330 {
  fill: #a23243;
}
.cls5-331 {
  fill: #9a3351;
}
.cls5-332 {
  fill: #758878;
}
.cls5-333 {
  fill: #aa906a;
}
.cls5-334 {
  fill: #7d8d65;
}
.cls5-335 {
  fill: #553e39;
}
.cls5-336 {
  fill: #ab8f69;
}
.cls5-337 {
  fill: #a7918a;
}
.cls5-338 {
  fill: #94333d;
}
.cls5-339 {
  fill: #a42b3a;
}
.cls5-340 {
  fill: #983c3f;
}
.cls5-341 {
  fill: #83373b;
}
.cls5-342 {
  fill: #739158;
}
.cls5-343 {
  fill: #953244;
}
.cls5-344 {
  fill: #aaa470;
}
.cls5-345 {
  fill: #ab284a;
}
.cls5-346 {
  fill: #9d3752;
}
.cls5-347 {
  fill: #863548;
}
.cls5-348 {
  fill: #746634;
}
.cls5-349 {
  fill: #b05e4a;
}
.cls5-350 {
  fill: #618354;
}
.cls5-351 {
  fill: #ccc1c0;
}
.cls5-352 {
  fill: #adad81;
}
.cls5-353 {
  fill: #9c2a33;
}
.cls5-354 {
  fill: #c3beb7;
}
.cls5-355 {
  fill: #86847f;
}
.cls5-356 {
  fill: #943d46;
}
.cls5-357 {
  fill: #7b8067;
}
.cls5-358 {
  fill: #e99e9a;
}
.cls5-359 {
  fill: #ac877a;
}
.cls5-360 {
  fill: #bbbfa2;
}
.cls5-361 {
  fill: #9b947f;
}
.cls5-362 {
  fill: #962b48;
}
.cls5-363 {
  fill: #9d2e3c;
}
.cls5-364 {
  fill: #7b7b5b;
}
.cls5-365 {
  fill: #78754e;
}
.cls5-366 {
  fill: #903c41;
}
.cls5-367 {
  fill: #a17439;
}
.cls5-368 {
  fill: #46462f;
}
.cls5-369 {
  fill: #90b389;
}
.cls5-370 {
  fill: #aebd9c;
}
.cls5-371 {
  fill: #beb99f;
}
.cls5-372 {
  fill: #b5b2a1;
}
.cls5-373 {
  fill: #727056;
}
.cls5-374 {
  fill: #788765;
}
.cls5-375 {
  fill: #5f896a;
}
.cls5-376 {
  fill: #93827c;
}
.cls5-377 {
  fill: #3e8a39;
}
.cls5-378 {
  fill: #ae2140;
}
.cls5-379 {
  fill: #40863a;
}
.cls5-380 {
  fill: #bdbfac;
}
.cls5-381 {
  fill: #a52a48;
}
.cls5-382 {
  fill: #637568;
}
.cls5-383 {
  fill: #949e82;
}
.cls5-384 {
  fill: #a72845;
}
.cls5-385 {
  fill: #60805f;
}
.cls5-386 {
  fill: #66783b;
}
.cls5-387 {
  fill: #9c9584;
}
.cls5-388 {
  fill: #b05c5b;
}
.cls5-389 {
  fill: #87af70;
}
.cls5-390 {
  fill: #84b372;
}
.cls5-391 {
  fill: #818258;
}
.cls5-392 {
  fill: #858056;
}
.cls5-393 {
  fill: #bfbf9b;
}
.cls5-394 {
  fill: #d9b6a6;
}
.cls5-395 {
  fill: #aa2341;
}
.cls5-396 {
  fill: #ceafaf;
}
.cls5-397 {
  fill: #d7beaa;
}
.cls5-398 {
  fill: #e2bcba;
}
.cls5-399 {
  fill: #cabda4;
}
.cls5-400 {
  fill: #8e706a;
}
.cls5-401 {
  fill: #e5bdc6;
}
.cls5-402 {
  fill: #9e2e41;
}
.cls5-403 {
  fill: #b89a8e;
}
.cls5-404 {
  fill: #a37680;
}
.cls5-405 {
  fill: #933848;
}
.cls5-406 {
  fill: #b0374f;
}
.cls5-407 {
  fill: #9d3548;
}
.cls5-408 {
  fill: #bf6179;
}
.cls5-409 {
  fill: #a0939a;
}
.cls5-410 {
  fill: #74bf72;
}
.cls5-411 {
  fill: #aa2c48;
}
.cls5-412 {
  fill: #a03d4f;
}
.cls5-413 {
  fill: #808c79;
}
.cls5-414 {
  fill: #946366;
}
.cls5-415 {
  fill: #62815b;
}
.cls5-416 {
  fill: #80957d;
}
.cls5-417 {
  fill: #9d2d44;
}
.cls5-418 {
  fill: #f6f4ec;
}
.cls5-419 {
  fill: #fbfbfb;
}
.cls5-420 {
  fill: #f3f3c4;
}
.cls5-421 {
  fill: #c2c2c2;
}
.cls5-422 {
  fill: #f0eeed;
}
.cls5-423 {
  fill: #e3e6e0;
}
.cls5-424 {
  fill: #c1bfbc;
}
.cls5-425 {
  fill: #d7d6d6;
}
.cls5-426 {
  fill: #bebbbe;
}
.cls5-427 {
  fill: #d5d4d3;
}
.cls5-428 {
  fill: #f2eaeb;
}
.cls5-429 {
  fill: #d8d7d6;
}
.cls5-430 {
  fill: #bfbdb9;
}
.cls5-431 {
  fill: #d6d3d4;
}
.cls5-432 {
  fill: #beb9b6;
}
.cls5-433 {
  fill: #c4b4b8;
}
.cls5-434 {
  fill: #f9f7f7;
}
.cls5-435 {
  fill: #e1dede;
}
.cls5-436 {
  fill: #e0dddd;
}
.cls5-437 {
  fill: #e3dfde;
}
.cls5-438 {
  fill: #e2d4d5;
}
.cls5-439 {
  fill: #f0f1e9;
}
.cls5-440 {
  fill: #eeeeed;
}
.cls5-441 {
  fill: #f0f0cc;
}
.cls5-442 {
  fill: #f6f3cd;
}
.cls5-443 {
  fill: #d7d4d4;
}
.cls5-444 {
  fill: #f6f6f2;
}
.cls5-445 {
  fill: #c0bdbc;
}
.cls5-446 {
  fill: #f0e7e3;
}
.cls5-447 {
  fill: #cdcbcb;
}
.cls5-448 {
  fill: #d6b2b9;
}
.cls5-449 {
  fill: #d3d0be;
}
.cls5-450 {
  fill: #148d16;
}
.cls5-451 {
  fill: #c4c2c2;
}
.cls5-452 {
  fill: #dbe578;
}
.cls5-453 {
  fill: #c2b8b8;
}
.cls5-454 {
  fill: #dcef85;
}
.cls5-455 {
  fill: #dad9d7;
}
.cls5-456 {
  fill: #dcdf7c;
}
.cls5-457 {
  fill: #c9e56b;
}
.cls5-458 {
  fill: #c0c0be;
}
.cls5-459 {
  fill: #c5c0c0;
}
.cls5-460 {
  fill: #c1c1bd;
}
.cls5-461 {
  fill: #c3bdbe;
}
.cls5-462 {
  fill: #f8f8f7;
}
.cls5-463 {
  fill: #e5dcdb;
}
.cls5-464 {
  fill: #f2efde;
}
.cls5-465 {
  fill: #dad9d8;
}
.cls5-466 {
  fill: #d9d8d8;
}
.cls5-467 {
  fill: #dad6d5;
}
.cls5-468 {
  fill: #bfbfbf;
}
.cls5-469 {
  fill: #d7d7d7;
}
.cls5-470 {
  fill: #d6d6d6;
}
.cls5-471 {
  fill: #d5d2d0;
}
.cls5-472 {
  fill: #d6d6d5;
}
.cls5-473 {
  fill: #d2d3d2;
}
.cls5-474 {
  fill: #118e0d;
}
.cls5-475 {
  fill: #c7b7ba;
}
.cls5-476 {
  fill: #f5f5f4;
}
.cls5-477 {
  fill: #f3f0f0;
}
.cls5-478 {
  fill: #1a8d10;
}
.cls5-479 {
  fill: #dbe67b;
}
.cls5-480 {
  fill: #f6f6f8;
}
.cls5-481 {
  fill: #f1eeeb;
}
.cls5-482 {
  fill: #dbe37f;
}
.cls5-483 {
  fill: #c2bfbd;
}
.cls5-484 {
  fill: #f5eee9;
}
.cls5-485 {
  fill: #c0c0bd;
}
.cls5-486 {
  fill: #bdbebd;
}
.cls5-487 {
  fill: #f2e6e4;
}
.cls5-488 {
  fill: #c3c0bc;
}
.cls5-489 {
  fill: #d8dc70;
}
.cls5-490 {
  fill: #e1ccb2;
}
.cls5-491 {
  fill: #eff1e7;
}
.cls5-492 {
  fill: #c3bebd;
}
.cls5-493 {
  fill: #d8e876;
}
.cls5-494 {
  fill: #bebebe;
}
.cls5-495 {
  fill: #f8f8f6;
}
.cls5-496 {
  fill: #ebf3c5;
}
.cls5-497 {
  fill: #f7c31f;
}
.cls5-498 {
  fill: #f6f9f9;
}
.cls5-499 {
  fill: #f0eee6;
}
.cls5-500 {
  fill: #faf3f2;
}
.cls5-501 {
  fill: #f2f2c8;
}
.cls5-502 {
  fill: #d5d4d4;
}
.cls5-503 {
  fill: #f8f5c0;
}
.cls5-504 {
  fill: #f6f0f2;
}
.cls5-505 {
  fill: #cbcbca;
}
.cls5-506 {
  fill: #fafaf6;
}
.cls5-507 {
  fill: #f3f1ec;
}
.cls5-508 {
  fill: #f7f7f1;
}
.cls5-509 {
  fill: #f3f3b2;
}
.cls5-510 {
  fill: #c0bebe;
}
.cls5-511 {
  fill: #f4f5c8;
}
.cls5-512 {
  fill: #f6f6f5;
}
.cls5-513 {
  fill: #f7f5eb;
}
.cls5-514 {
  fill: #f4f2ef;
}
.cls5-515 {
  fill: #edebd3;
}
.cls5-516 {
  fill: #eceae6;
}
.cls5-517 {
  fill: #fbfcfc;
}
.cls5-518 {
  fill: #f1e5de;
}
.cls5-519 {
  fill: #ece0d7;
}
.cls5-520 {
  fill: #f3dfde;
}
.cls5-521 {
  fill: #e6dfde;
}
.cls5-522 {
  fill: #c6b5b9;
}
.cls5-523 {
  fill: #f6f3f2;
}
.cls5-524 {
  fill: #c0b7b7;
}
.cls5-525 {
  fill: #d8d8d8;
}
.cls5-526 {
  fill: #f1f1ee;
}
.cls5-527 {
  fill: #ebdcc0;
}
.cls5-528 {
  fill: #f6f4f1;
}
.cls5-529 {
  fill: #eff6f2;
}
.cls5-530 {
  fill: #d9d8d7;
}
.cls5-531 {
  fill: #f9efeb;
}
.cls5-532 {
  fill: #fbf5f0;
}
.cls5-533 {
  fill: #019103;
}
.cls5-534 {
  fill: #a6e968;
}
.cls5-535 {
  fill: #d9d9d6;
}
.cls5-536 {
  fill: #d8cdcd;
}
.cls5-537 {
  fill: #dcdcdc;
}
.cls5-538 {
  fill: #0f9205;
}
.cls5-539 {
  fill: #288a07;
}
.cls5-540 {
  fill: #d6d7d6;
}
.cls5-541 {
  fill: #deddd4;
}
.cls5-542 {
  fill: #c9c9c4;
}
.cls5-543 {
  fill: #c2c4bf;
}
.cls5-544 {
  fill: #cbcbc9;
}
.cls5-545 {
  fill: #178e07;
}
.cls5-546 {
  fill: #f2eeee;
}
.cls5-547 {
  fill: #dedbdb;
}
.cls5-548 {
  fill: #e0d4c7;
}
.cls5-549 {
  fill: #dcd4d3;
}
.cls5-550 {
  fill: #dec9cd;
}
.cls5-551 {
  fill: #dcdbd8;
}
.cls5-552 {
  fill: #f5f5f3;
}
.cls5-553 {
  fill: #faf8f9;
}
.cls5-554 {
  fill: #fcfdfc;
}
.cls5-555 {
  fill: #fdfcfd;
}
.cls5-556 {
  fill: #148d08;
}
.cls5-557 {
  fill: #ebeeb5;
}
.cls5-558 {
  fill: #c6c4c5;
}
.cls5-559 {
  fill: #f0f8c0;
}
.cls5-560 {
  fill: #dce99d;
}
.cls5-561 {
  fill: #e1e7a9;
}
.cls5-562 {
  fill: #dde7a9;
}
.cls5-563 {
  fill: #eff0b3;
}
.cls5-564 {
  fill: #e3eda7;
}
.cls5-565 {
  fill: #e1e384;
}
.cls5-566 {
  fill: #218c53;
}
.cls5-567 {
  fill: #2a8d41;
}
.cls5-568 {
  fill: #efefa7;
}
.cls5-569 {
  fill: #e5f094;
}
.cls5-570 {
  fill: #e6f58f;
}
.cls5-571 {
  fill: #36aa56;
}
.cls5-572 {
  fill: #24a48e;
}
.cls5-573 {
  fill: #32a93f;
}
.cls5-574 {
  fill: #29844c;
}
.cls5-575 {
  fill: #adf324;
}
.cls5-576 {
  fill: #26ab37;
}
.cls5-577 {
  fill: #ddf08e;
}
.cls5-578 {
  fill: #8232b3;
}
.cls5-579 {
  fill: #60abb4;
}
.cls5-580 {
  fill: #2c880b;
}
.cls5-581 {
  fill: #72f1c9;
}
.cls5-582 {
  fill: #258454;
}
.cls5-583 {
  fill: #8033ab;
}
.cls5-584 {
  fill: #70adbe;
}
.cls5-585 {
  fill: #74efc7;
}
.cls5-586 {
  fill: #7df0d0;
}
.cls5-587 {
  fill: #2d8703;
}
.cls5-588 {
  fill: #2d8769;
}
.cls5-589 {
  fill: #6ad2b1;
}
.cls5-590 {
  fill: #88dbc1;
}
.cls5-591 {
  fill: #7bebb9;
}
.cls5-592 {
  fill: #8bd8cb;
}
.cls5-593 {
  fill: #7ce5b3;
}
.cls5-594 {
  fill: #228260;
}
.cls5-595 {
  fill: #88edc1;
}
.cls5-596 {
  fill: #8cf3af;
}
.cls5-597 {
  fill: #8ae52c;
}
.cls5-598 {
  fill: #89da32;
}
.cls5-599 {
  fill: #92e23e;
}
.cls5-600 {
  fill: #83e73c;
}
.cls5-601 {
  fill: #57b292;
}
.cls5-602 {
  fill: #74dba3;
}
.cls5-603 {
  fill: #8dd2c4;
}
.cls5-604 {
  fill: #84ea3b;
}
.cls5-605 {
  fill: #b6a9ce;
}
.cls5-606 {
  fill: #59af9a;
}
.cls5-607 {
  fill: #5bb5a5;
}
.cls5-608 {
  fill: #5cb299;
}
.cls5-609 {
  fill: #5fb097;
}
.cls5-610 {
  fill: #71e8b1;
}
.cls5-611 {
  fill: #6fecb3;
}
.cls5-612 {
  fill: #77e6a5;
}
.cls5-613 {
  fill: #88eabb;
}
.cls5-614 {
  fill: #57b097;
}
.cls5-615 {
  fill: #78eeaf;
}
.cls5-616 {
  fill: #84ebbd;
}
.cls5-617 {
  fill: #79f0c0;
}
.cls5-618 {
  fill: #63d74b;
}
.cls5-619 {
  fill: #ecee9b;
}
.cls5-620 {
  fill: #f4f6e9;
}
.cls5-621 {
  fill: #d4dea1;
}
.cls5-622 {
  fill: #dbe67a;
}
.cls5-623 {
  fill: #1799a3;
}
.cls5-624 {
  fill: #dce282;
}
.cls5-625 {
  fill: #cadc78;
}
.cls5-626 {
  fill: #bae592;
}
.cls5-627 {
  fill: #7ccf80;
}
.cls5-628 {
  fill: #67adc8;
}
.cls5-629 {
  fill: #7af1d0;
}
.cls5-630 {
  fill: #80eed2;
}
.cls5-631 {
  fill: #85db37;
}
.cls5-632 {
  fill: #dbeee8;
}
.cls5-633 {
  fill: #20a13d;
}
.cls5-634 {
  fill: #88e1c3;
}
.cls5-635 {
  fill: #83dbc8;
}
.cls5-636 {
  fill: #87ebc4;
}
.cls5-637 {
  fill: #4fc486;
}
.cls5-638 {
  fill: #7aec46;
}
.cls5-639 {
  fill: #59c89a;
}
.cls5-640 {
  fill: #74e9b1;
}
.cls5-641 {
  fill: #54b08d;
}
.cls5-642 {
  fill: #7ce752;
}
.cls5-643 {
  fill: #6de5a0;
}
.cls5-644 {
  fill: #ade1b0;
}
.cls5-645 {
  fill: #79e8be;
}
.cls5-646 {
  fill: #7ee9a4;
}
.cls5-647 {
  fill: #6beead;
}
.cls5-648 {
  fill: #68e39c;
}
.cls5-649 {
  fill: #68eea5;
}
.cls5-650 {
  fill: #55b298;
}
.cls5-651 {
  fill: #76ecbd;
}
.cls5-652 {
  fill: #73eeb2;
}
.cls5-653 {
  fill: #89efcb;
}
.cls5-654 {
  fill: #dfd0cc;
}
.cls5-655 {
  fill: #d8d7d7;
}
.cls5-656 {
  fill: #d5d6d5;
}
.cls5-657 {
  fill: #e1dadb;
}
.cls5-658 {
  fill: #cfafb2;
}
.cls5-659 {
  fill: #dfdbdb;
}
.cls5-660 {
  fill: #e6e4ca;
}
.cls5-661 {
  fill: #d5d5d5;
}
.cls5-662 {
  fill: #cacac9;
}
.cls5-663 {
  fill: #dbdbda;
}
.cls5-664 {
  fill: #cab2b0;
}
.cls5-665 {
  fill: #decbcf;
}
.cls5-666 {
  fill: #f9f7f6;
}
.cls5-667 {
  fill: #f6f4f2;
}
.cls5-668 {
  fill: #dde8aa;
}
.cls5-669 {
  fill: #f9f2f1;
}
.cls5-670 {
  fill: #d8c6c9;
}
.cls5-671 {
  fill: #dbd8da;
}
.cls5-672 {
  fill: #ebe2e1;
}
.cls5-673 {
  fill: #f8f4f5;
}
.cls5-674 {
  fill: #f7eeee;
}
.cls5-675 {
  fill: #faedee;
}
.cls5-676 {
  fill: #dadada;
}
.cls5-677 {
  fill: #2a8310;
}
.cls5-678 {
  fill: #f6f4f4;
}
.cls5-679 {
  fill: #f9f6eb;
}
.cls5-680 {
  fill: #c3bdbf;
}
.cls5-681 {
  fill: #cbb3b8;
}
.cls5-682 {
  fill: #c1bebd;
}
.cls5-683 {
  fill: #f5f1f1;
}
.cls5-684 {
  fill: #f6f4f3;
}
.cls5-685 {
  fill: #f5efbd;
}
.cls5-686 {
  fill: #f1efee;
}
.cls5-687 {
  fill: #f7f7c3;
}
.cls5-688 {
  fill: #f6f384;
}
.cls5-689 {
  fill: #f6f6e3;
}
.cls5-690 {
  fill: #fcfbfb;
}
.cls5-691 {
  fill: #f7f7f6;
}
.cls5-692 {
  fill: #f6f6f0;
}
.cls5-693 {
  fill: #edebe9;
}
.cls5-694 {
  fill: #f6f0ed;
}
.cls5-695 {
  fill: #f3f1f0;
}
.cls5-696 {
  fill: #d8d7d5;
}
.cls5-697 {
  fill: #f1eceb;
}
.cls5-698 {
  fill: #a9e75c;
}
.cls5-699 {
  fill: #eaefdd;
}
.cls5-700 {
  fill: #c8de9b;
}
.cls5-701 {
  fill: #1f5ae2;
}
.cls5-702 {
  fill: #3a7904;
}
.cls5-703 {
  fill: #d6eabe;
}
.cls5-704 {
  fill: #c4d3bd;
}
.cls5-705 {
  fill: #bec9b4;
}
.cls5-706 {
  fill: #eff7ee;
}
.cls5-707 {
  fill: #ecf5e8;
}
.cls5-708 {
  fill: #bee8e2;
}
.cls5-709 {
  fill: #fdfdfd;
}
.cls5-710 {
  fill: #bcb9ba;
}
.cls5-711 {
  fill: #1e915b;
}
.cls5-712 {
  fill: #f8f5f1;
}
.cls5-713 {
  fill: #f5f79a;
}
.cls5-714 {
  fill: #fcf4e0;
}
.cls5-715 {
  fill: #d2d57e;
}
.cls5-716 {
  fill: #f2f6a9;
}
.cls5-717 {
  fill: #aed26d;
}
.cls5-718 {
  fill: #f5f6b4;
}
.cls5-719 {
  fill: #f3f5b4;
}
.cls5-720 {
  fill: #c4d25a;
}
.cls5-721 {
  fill: #f9f7db;
}
.cls5-722 {
  fill: #f9e9e4;
}
.cls5-723 {
  fill: #c81233;
}
.cls5-724 {
  fill: #edf4f6;
}
.cls5-725 {
  fill: #f3f4cb;
}
.cls5-726 {
  fill: #b1eae8;
}
.cls5-727 {
  fill: #fbf7e3;
}
.cls5-728 {
  fill: #f7f4e1;
}
.cls5-729 {
  fill: #e3dbd2;
}
.cls5-730 {
  fill: #9ed463;
}
.cls5-731 {
  fill: #ecebd9;
}
.cls5-732 {
  fill: #338716;
}
.cls5-733 {
  fill: #f1f58b;
}
.cls5-734 {
  fill: #3b8d16;
}
.cls5-735 {
  fill: #f1f2ec;
}
.cls5-736 {
  fill: #e8df82;
}
.cls5-737 {
  fill: #f6eee8;
}
.cls5-738 {
  fill: #eef29f;
}
.cls5-739 {
  fill: #ecebea;
}
.cls5-740 {
  fill: #f6f2e2;
}
.cls5-741 {
  fill: #e8daca;
}
.cls5-742 {
  fill: #f4f992;
}
.cls5-743 {
  fill: #f6f7be;
}
.cls5-744 {
  fill: #d4bdbd;
}
.cls5-745 {
  fill: #e8ee91;
}
.cls5-746 {
  fill: #d7f0c6;
}
.cls5-747 {
  fill: #2b7902;
}
.cls5-748 {
  fill: #ecc420;
}
.cls5-749 {
  fill: #a4e75d;
}
.cls5-750 {
  fill: #eff168;
}
.cls5-751 {
  fill: #f0c11f;
}
.cls5-752 {
  fill: #f0c428;
}
.cls5-753 {
  fill: #daebbf;
}
.cls5-754 {
  fill: #bacf49;
}
.cls5-755 {
  fill: #a9e464;
}
.cls5-756 {
  fill: #d3eebf;
}
.cls5-757 {
  fill: #b5e2a2;
}
.cls5-758 {
  fill: #dfcdc9;
}
.cls5-759 {
  fill: #e4e1ae;
}
.cls5-760 {
  fill: #d60436;
}
.cls5-761 {
  fill: #56a765;
}
.cls5-762 {
  fill: #f0ebc9;
}
.cls5-763 {
  fill: #eff6b3;
}
.cls5-764 {
  fill: #f6f7e6;
}
.cls5-765 {
  fill: #ecf1d6;
}
.cls5-766 {
  fill: #d3d668;
}
.cls5-767 {
  fill: #f3f5ab;
}
.cls5-768 {
  fill: #e6f1a5;
}
.cls5-769 {
  fill: #f1f5a4;
}
.cls5-770 {
  fill: #f5f695;
}
.cls5-771 {
  fill: #f4f6d5;
}
.cls5-772 {
  fill: #e8f0a1;
}
.cls5-773 {
  fill: #c51235;
}
.cls5-774 {
  fill: #eaf35e;
}
.cls5-775 {
  fill: #bfe172;
}
.cls5-776 {
  fill: #4aae57;
}
.cls5-777 {
  fill: #d0d1cf;
}
.cls5-778 {
  fill: #ded6d8;
}
.cls5-779 {
  fill: #cca8b3;
}
.cls5-780 {
  fill: #dbe9aa;
}
.cls5-781 {
  fill: #eee3e4;
}
.cls5-782 {
  fill: #dfdcd9;
}
.cls5-783 {
  fill: #d7d8d7;
}
.cls5-784 {
  fill: #d1d1d1;
}
.cls5-785 {
  fill: #66b22f;
}
.cls5-786 {
  fill: #d6d5d5;
}
.cls5-787 {
  fill: #eff1ba;
}
.cls5-788 {
  fill: #f5f6b2;
}
.cls5-789 {
  fill: #f3f5c0;
}
.cls5-790 {
  fill: #bb1f2f;
}
.cls5-791 {
  fill: #dae75a;
}
.cls5-792 {
  fill: #bdea8d;
}
.cls5-793 {
  fill: #3d890f;
}
.cls5-794 {
  fill: #fafaf1;
}
.cls5-795 {
  fill: #f0f5b3;
}
.cls5-796 {
  fill: #f5f78d;
}
.cls5-797 {
  fill: #faf3ed;
}
.cls5-798 {
  fill: #bfd5ae;
}
.cls5-799 {
  fill: #b52430;
}
.cls5-800 {
  fill: #eff3d6;
}
.cls5-801 {
  fill: #379009;
}
.cls5-802 {
  fill: #58b699;
}
.cls5-803 {
  fill: #6defc0;
}
.cls5-804 {
  fill: #75f0c4;
}
.cls5-805 {
  fill: #7aeea9;
}
.cls5-806 {
  fill: #c038ca;
}
.cls5-807 {
  fill: #f2ef82;
}
.cls5-808 {
  fill: #f9f5ea;
}
.cls5-809 {
  fill: #cf0d35;
}
.cls5-810 {
  fill: #b72747;
}
.cls5-811 {
  fill: #f9eda3;
}
.cls5-812 {
  fill: #f6ec6b;
}
.cls5-813 {
  fill: #8434a3;
}
.cls5-814 {
  fill: #83f6c4;
}
.cls5-815 {
  fill: #b113cd;
}
.cls5-816 {
  fill: #f7f5c2;
}
.cls5-817 {
  fill: #ebefbb;
}
.cls5-818 {
  fill: #f6efb3;
}
.cls5-819 {
  fill: #971ecf;
}
.cls5-820 {
  fill: #9a1ad3;
}
.cls5-821 {
  fill: #f1eb8d;
}
.cls5-822 {
  fill: #f5f5f5;
}
.cls5-823 {
  fill: #edebc9;
}
.cls5-824 {
  fill: #bfacb5;
}
.cls5-825 {
  fill: #d7d6d4;
}
.cls5-826 {
  fill: #d3d1d1;
}
.cls5-827 {
  fill: #f9f9f9;
}
.cls5-828 {
  fill: #d7d7d6;
}
.cls5-829 {
  fill: #f8eee4;
}
.cls5-830 {
  fill: #536862;
}
.cls5-831 {
  fill: #7be4b5;
}
.cls5-832 {
  fill: #6ca898;
}
.cls5-833 {
  fill: #f4f1c8;
}
.cls5-834 {
  fill: #e7ea91;
}
.cls5-835 {
  fill: #f2f4e7;
}
.cls5-836 {
  fill: #e7f48e;
}
.cls5-837 {
  fill: #e6f1f0;
}
.cls5-838 {
  fill: #f8fbf7;
}
.cls5-839 {
  fill: #f5f6e8;
}
.cls5-840 {
  fill: #f4f68b;
}
.cls5-841 {
  fill: #c0292f;
}
.cls5-842 {
  fill: #e7eed5;
}
.cls5-843 {
  fill: #f9f6f2;
}
.cls5-844 {
  fill: #f7f8d4;
}
.cls5-845 {
  fill: #faf8d7;
}
.cls5-846 {
  fill: #e3d8a6;
}
.cls5-847 {
  fill: #f7f4f1;
}
.cls5-848 {
  fill: #cce09f;
}
.cls5-849 {
  fill: #eff689;
}
.cls5-850 {
  fill: #fadba8;
}
.cls5-851 {
  fill: #f9d2b9;
}
.cls5-852 {
  fill: #f1d2af;
}
.cls5-853 {
  fill: #f6de96;
}
.cls5-854 {
  fill: #39882f;
}
.cls5-855 {
  fill: #e2e6d6;
}
.cls5-856 {
  fill: #e5ecd5;
}
.cls5-857 {
  fill: #e0cdb5;
}
.cls5-858 {
  fill: #cee7d1;
}
.cls5-859 {
  fill: #edc9bc;
}
.cls5-860 {
  fill: #eaf383;
}
.cls5-861 {
  fill: #f1ecce;
}
.cls5-862 {
  fill: #e4d89c;
}
.cls5-863 {
  fill: #f1edc8;
}
.cls5-864 {
  fill: #cb1a1e;
}
.cls5-865 {
  fill: #109003;
}
.cls5-866 {
  fill: #eaf0ce;
}
.cls5-867 {
  fill: #eaf3e3;
}
.cls5-868 {
  fill: #ececc8;
}
.cls5-869 {
  fill: #faedeb;
}
.cls5-870 {
  fill: #e8f1d4;
}
.cls5-871 {
  fill: #e4f4d6;
}
.cls5-872 {
  fill: #eec424;
}
.cls5-873 {
  fill: #b7e7a7;
}
.cls5-874 {
  fill: #f2c324;
}
.cls5-875 {
  fill: #edf3df;
}
.cls5-876 {
  fill: #f7f7e8;
}
.cls5-877 {
  fill: #fbf9e8;
}
.cls5-878 {
  fill: #dae5d5;
}
.cls5-879 {
  fill: #d6eaac;
}
.cls5-880 {
  fill: #dad6aa;
}
.cls5-881 {
  fill: #e8eacf;
}
.cls5-882 {
  fill: #c92440;
}
.cls5-883 {
  fill: #c2222d;
}
.cls5-884 {
  fill: #dad7d1;
}
.cls5-885 {
  fill: #d3d3d2;
}
.cls5-886 {
  fill: #f3f794;
}
.cls5-887 {
  fill: #f1f2b1;
}
.cls5-888 {
  fill: #479115;
}
.cls5-889 {
  fill: #b8d0c0;
}
.cls5-890 {
  fill: #d3d6b2;
}
.cls5-891 {
  fill: #d3d6d4;
}
.cls5-892 {
  fill: #d4d7d5;
}
.cls5-893 {
  fill: #d6d6d8;
}
.cls5-894 {
  fill: #d6d2d4;
}
.cls5-895 {
  fill: #c2183e;
}
.cls5-896 {
  fill: #e2e9c9;
}
.cls5-897 {
  fill: #d94039;
}
.cls5-898 {
  fill: #348a07;
}
.cls5-899 {
  fill: #1c8c12;
}
.cls5-900 {
  fill: #378c32;
}
.cls5-901 {
  fill: #4d6d98;
}
.cls5-902 {
  fill: #f0ee8c;
}
.cls5-903 {
  fill: #ebf398;
}
.cls5-904 {
  fill: #468e18;
}
.cls5-905 {
  fill: #58ab83;
}
.cls5-906 {
  fill: #f3f4bd;
}
.cls5-907 {
  fill: #f2eec2;
}
.cls5-908 {
  fill: #9cd150;
}
.cls5-909 {
  fill: #f1f1c0;
}
.cls5-910 {
  fill: #f6f9f0;
}
.cls5-911 {
  fill: #f3f4c3;
}
.cls5-912 {
  fill: #eadfdc;
}
.cls5-913 {
  fill: #e3f0b4;
}
.cls5-914 {
  fill: #e4ef86;
}
.cls5-915 {
  fill: #ddd5c6;
}
.cls5-916 {
  fill: #e7e9db;
}
.cls5-917 {
  fill: #eae9dc;
}
.cls5-918 {
  fill: #d0d777;
}
.cls5-919 {
  fill: #e1f3d1;
}
.cls5-920 {
  fill: #f2f2ca;
}
.cls5-921 {
  fill: #e7dfda;
}
.cls5-922 {
  fill: #f7f0de;
}
.cls5-923 {
  fill: #d9d9ce;
}
.cls5-924 {
  fill: #be244b;
}
.cls5-925 {
  fill: #f7d0cb;
}
.cls5-926 {
  fill: #2f901c;
}
.cls5-927 {
  fill: #e7d1b6;
}
.cls5-928 {
  fill: #a7e3c9;
}
.cls5-929 {
  fill: #d9eae6;
}
.cls5-930 {
  fill: #e1ddbe;
}
.cls5-931 {
  fill: #edf5b7;
}
.cls5-932 {
  fill: #80ce68;
}
.cls5-933 {
  fill: #d0e1d4;
}
.cls5-934 {
  fill: #e7e2ce;
}
.cls5-935 {
  fill: #539e28;
}
.cls5-936 {
  fill: #c9c3bc;
}
.cls5-937 {
  fill: #f0e3c9;
}
.cls5-938 {
  fill: #f4f5be;
}
.cls5-939 {
  fill: #dfeda3;
}
.cls5-940 {
  fill: #d0dab5;
}
.cls5-941 {
  fill: #f1f5b1;
}
.cls5-942 {
  fill: #0c8f0f;
}
.cls5-943 {
  fill: #1d5fda;
}
.cls5-944 {
  fill: #3b3922;
}
.cls5-945 {
  fill: #c4173c;
}
.cls5-946 {
  fill: #ebe3bd;
}
.cls5-947 {
  fill: #edecc3;
}
.cls5-948 {
  fill: #e5d9c3;
}
.cls5-949 {
  fill: #e1e3bf;
}
.cls5-950 {
  fill: #ccdac2;
}
.cls5-951 {
  fill: #cfd9c8;
}
.cls5-952 {
  fill: #b5da99;
}
.cls5-953 {
  fill: #c62b45;
}
.cls5-954 {
  fill: #f1f2df;
}
.cls5-955 {
  fill: #f7f7e0;
}
.cls5-956 {
  fill: #3f61c8;
}
.cls5-957 {
  fill: #b9d5aa;
}
.cls5-958 {
  fill: #6cc25f;
}
.cls5-959 {
  fill: #d9e3e6;
}
.cls5-960 {
  fill: #eef3be;
}
.cls5-961 {
  fill: #bb2341;
}
.cls5-962 {
  fill: #e5c9c0;
}
.cls5-963 {
  fill: #e6dfe7;
}
.cls5-964 {
  fill: #e1d6d2;
}
.cls5-965 {
  fill: #d2e6cd;
}
.cls5-966 {
  fill: #1359e5;
}
.cls5-967 {
  fill: #225cd9;
}
.cls5-968 {
  fill: #1d54e7;
}
.cls5-969 {
  fill: #5cb439;
}
.cls5-970 {
  fill: #70ce52;
}
.cls5-971 {
  fill: #5aba4c;
}
.cls5-972 {
  fill: #5fd24f;
}
.cls5-973 {
  fill: #415fcc;
}
.cls5-974 {
  fill: #dce0b7;
}
.cls5-975 {
  fill: #e7e6c9;
}
.cls5-976 {
  fill: #ecdfa5;
}
.cls5-977 {
  fill: #b51e3d;
}
.cls5-978 {
  fill: #b51c36;
}
.cls5-979 {
  fill: #f0d8c7;
}
.cls5-980 {
  fill: #398f2c;
}
.cls5-981 {
  fill: #edf1e4;
}
.cls5-982 {
  fill: #ddf0c4;
}
.cls5-983 {
  fill: #d4dfb8;
}
.cls5-984 {
  fill: #edf2cf;
}
.cls5-985 {
  fill: #edf5c8;
}
.cls5-986 {
  fill: #b61f44;
}
.cls5-987 {
  fill: #dcead3;
}
.cls5-988,
.cls5-989,
.cls5-990,
.cls5-991,
.cls5-992,
.cls5-993,
.cls5-994,
.cls5-995,
.cls5-996,
.cls5-997,
.cls5-999 {
  stroke: #f20032;
}
.cls5-989 {
  fill: #00d000;
}
.cls5-989,
.cls5-990,
.cls5-999 {
  opacity: 0.5;
}
.cls5-990 {
  fill: #aeb900;
}
.cls5-991 {
  fill: #bd0015;
}
.cls5-991,
.cls5-992,
.cls5-993,
.cls5-994,
.cls5-995,
.cls5-997,
.cls5-998 {
  opacity: 0.7;
}
.cls5-992 {
  fill: #00fffb;
}
.cls5-993 {
  fill: #a21900;
}
.cls5-994 {
  fill: #ba00a6;
}
.cls5-995 {
  fill: #00108d;
}
.cls5-996 {
  fill: #00b900;
  opacity: 0.73;
}
.cls5-997 {
  fill: #1910ff;
}
.cls5-998 {
  fill: #008a00;
  stroke: #000;
}
.cls5-999 {
  fill: #ff33bc;
}
.cls5-1000 {
  fill: #0e4b92;
}
.cls5-1001 {
  fill: #002462;
}
